export const isLocationConfirmed = state => state.getIn(['storage', 'data', 'confirmLocation']) || false;
export const getDetails = state => state.getIn(['storage', 'data']);
export const getDetail = (state, detail) => state.getIn(['storage', 'data', detail]);
export const isInitialized = state => state.getIn(['storage', 'initialized']);
export const getCheckoutSurname = state => {
  const name = getDetail(state, 'name');

  if (!name) {
    return '';
  }

  const parts = name.split(' ');
  return parts[parts.length - 1];
};
