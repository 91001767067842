import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';

import OrderSummary from 'components/OrderReview/OrderSummary';
import { selectTaxTotals, selectTaxTotal } from 'selectors/order';
import { productType, promotionType } from 'selectors/orderItemTypes';

const TabOrderSummary = ({ order, taxBreakdown, taxTotal }) => {
  if (!order.get('items')?.size) return null;

  const payments = order
    .get('payments')
    ?.filter(payment => payment.get('state') === 'completed' || payment.get('state') === 'authorized');

  return (
    <OrderSummary
      productItems={order.get('items')?.filter(item => item.get('type') === productType)}
      promotionalItems={order.get('items')?.filter(item => item.get('type') === promotionType)}
      chargeItems={order
        .get('items')
        ?.filter(item => [productType, promotionType].indexOf(item.get('type')) === -1)}
      orderTotal={order.get('total')}
      remainingTotal={order.get('remaining_due')}
      taxTotal={taxTotal}
      taxBreakdown={taxBreakdown}
      finalTotal={order.get('total')}
      showEdit={false}
      showGratuity={false}
      showCoupon={false}
      isTab={true}
      payments={payments}
    />
  );
};

TabOrderSummary.defaultProps = {
  order: new Map(),
};

TabOrderSummary.propTypes = {
  order: PropTypes.instanceOf(Map),
  taxBreakdown: PropTypes.instanceOf(List),
  taxTotal: PropTypes.number,
};

export default connect((state, { order }) => {
  const taxBreakdown = selectTaxTotals(state, order);
  return {
    taxBreakdown,
    taxTotal: selectTaxTotal(taxBreakdown),
  };
})(TabOrderSummary);
