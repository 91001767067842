import React, { FC } from 'react';
import { List } from 'immutable';
import PaymentLineItem from './PaymentLineItem';

interface Payment {
  amount: number;
  method: string;
}

interface PaymentsProps {
  payments: List<Payment>;
}

const Payments: FC<PaymentsProps> = ({ payments }) => {
  const getName = (payment: Payment) =>
    payment.getIn(['details', 'name']) ?? payment.get('method');

  return (
    <>
      {payments.map((payment, index) => (
        <PaymentLineItem key={index} price={payment.getIn(['amount'])} text={getName(payment)} />
      ))}
    </>
  );
}

export default Payments;
