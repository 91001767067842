import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMessage, ButtonsContainer, MessageContainer, SeparatorBlock, StyledH2 } from './styles';
import messages from './messages';
import ActionButton from '../../ActionButton';
import globalMessages from '../../globalMessages';
import FeedbackButton from '../../Feedback/FeedbackButton';
import { fetchQuestions } from '../../../actions/feedback';
import { showFeedbackButton } from '../../../selectors/feedback';

export const OrderAgain: React.FC<{ serviceId: number; kioskMode: boolean; onReturnClick: () => void }> = ({
  serviceId,
  kioskMode,
  onReturnClick,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceId) {
      dispatch(fetchQuestions(serviceId));
    }
  }, [dispatch, serviceId]);

  const returnUrl = !kioskMode && serviceId ? `/service/${serviceId}` : '/';
  const showFeedback = useSelector(state => showFeedbackButton(state, serviceId));
  return (
    <>
      <SeparatorBlock />
      <MessageContainer>
        <StyledH2>
          <FormattedMessage {...messages.orderAgain} />
        </StyledH2>
        <ActionMessage>
          <FormattedMessage {...messages.orderAgainMessage} />
        </ActionMessage>
        <ButtonsContainer>
          <ActionButton
            onClick={kioskMode ? onReturnClick : undefined}
            to={returnUrl}
            forceReload={kioskMode}
            primaryButton={true}
            label={<FormattedMessage {...globalMessages.returnToMenu} />}
          />
          {showFeedback && (
            <>
              <FeedbackButton serviceId={serviceId} trackingLabel="Thankyou page" />
            </>
          )}
        </ButtonsContainer>
      </MessageContainer>
    </>
  );
};
