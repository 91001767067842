import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getServiceLocationNames, selectFulfilmentMethod } from 'selectors/root';
import AutocompleteField from 'components/Form/Fields/AutocompleteField';
import { getDetail } from '../../../../selectors/storage';

const propTypes = {
  field: PropTypes.object.isRequired,
  locationNames: PropTypes.array,
  serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLocked: PropTypes.func.isRequired,
  getLocation: PropTypes.string,
};

const LocationName = props => (
  <AutocompleteField
    {...props}
    disabled={props.isLocked()}
    type={props.locationNames.length ? 'text' : 'number'}
    autocompleteItems={props.locationNames}
  />
);

LocationName.propTypes = propTypes;

const mapStateToProps = (state, { field, locationKey, serviceId }) => ({
  locationNames: getServiceLocationNames(state, locationKey, serviceId).toJS(),
  isLocked: () => {
    const fulfilmentMethod = selectFulfilmentMethod(state);
    const isFieldAutoPopulated = getDetail(state, `${field.name}AutoPopulated`);

    return fulfilmentMethod?.get('lock_location_on_auto_populate') && isFieldAutoPopulated;
  },
  getLocation: getDetail(state, field.name),
});

export default connect(mapStateToProps)(LocationName);
