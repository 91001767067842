import { fromJS, List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { fetchProduct } from 'actions/browse';
import { addProductToOrder, updateItem } from 'actions/order';
import { popSheetInfoAndCustomisation } from 'actions/UI';
import { AlertContent, AlertHeader, AlertTitle } from 'assets/styles/sharedStyles';
import NavigationButtons from 'components/Alert/alerts/ProductOptins/NavigationButtons';
import Loading from 'components/Loading/index';
import MessageBlock from 'components/MessageBlock';
import Modifier from 'components/Modifier';
import productMessages from 'components/Product/messages';
import ProductQuantity from 'components/ProductQuantity';
import TextArea from 'components/TextArea';
import {
  backToItemMenuPage,
  getProductById,
  getProductModifiers,
  getScheduleById,
  selectServiceId,
} from 'selectors/browse';
import { shouldAllowItemNotes } from 'selectors/features';
import { getOrderItemByIndex } from 'selectors/order';
import { isServiceBrowseOnly } from 'selectors/root';
import { gtmDataLayerPush, reactPixel } from 'utils';
import { getLocale } from 'selectors/user';

import Accordion from 'components/Accordion';
import OptionLabel from 'components/OptionLabel';
import messages from './messages';
import { QuantityContainer } from './styles';
import ModifierProductInfo from './ModifierProductInfo';

export class ProductOptins extends React.Component {
  static defaultProps = {
    popSheetInfoAndCustomisation: () => undefined,
    showBaseModifiers: true,
    browseOnly: false,
  };

  static propTypes = {
    add: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    closeAlert: PropTypes.func.isRequired,
    popSheetInfoAndCustomisation: PropTypes.func,
    item: PropTypes.object.isRequired,
    product: PropTypes.instanceOf(Map).isRequired,
    productModifiers: PropTypes.instanceOf(List).isRequired,
    fetchProduct: PropTypes.func.isRequired,
    getProductById: PropTypes.func.isRequired,
    selectSchedule: PropTypes.func.isRequired,
    source: PropTypes.string,
    showBaseModifiers: PropTypes.bool,
    backToItemMenuPage: PropTypes.func,
    allowItemNotes: PropTypes.bool,
    browseOnly: PropTypes.bool,
    currentLocale: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      errors: [],
      notes: props.item?.get('notes') || '',
      quantity: props.item?.get('quantity') || 1,
      modifiers: props.item?.get('modifiers') || new List(),
      modifierInfo: null,
      erroringModifiers: {},
    };
  }

  componentDidMount() {
    this.initialiseData();
  }

  async initialiseData() {
    const { getProductById, item, fetchProduct } = this.props;

    // Check if product is in state
    const mainProduct = getProductById(item.get('productId'));
    const baseProduct = getProductById(item.get('base_product_id'));

    try {
      // Fetch the product if not in state or modifiers are missing
      if (mainProduct.isEmpty() || typeof mainProduct.get('modifier_groups') === 'undefined') {
        await fetchProduct(item.get('productId'));
      }

      // Fetch base product if it's also missing
      if (
        (baseProduct.isEmpty() || typeof baseProduct.get('modifier_groups') === 'undefined') &&
        item.get('base_product_id', null) !== null
      ) {
        await fetchProduct(item.get('base_product_id'));
      }
    } catch (e) {
      console.error(e);
    }
  }

  getSelectedModifierValues = modifier => {
    const modifierState = this.state.modifiers.find(m => m.getIn(['modifier', 'id']) === modifier.get('id'));
    return modifierState ? modifierState.get('values').toJS() : [];
  };

  // eslint-disable-next-line class-methods-use-this
  transformModifierValues = value => (typeof value === 'string' ? [{ value }] : value);

  updateModifierValues = (modifier, vals) => {
    const group = modifier.get('groupId');
    const values = this.transformModifierValues(vals, modifier);
    const existing = this.state.modifiers.findKey(
      entry => entry.getIn(['modifier', 'id']) === modifier.get('id')
    );
    let modifiers;
    if (existing !== undefined) {
      modifiers =
        values.length === 0
          ? this.state.modifiers.remove(existing)
          : this.state.modifiers.setIn([existing, 'values'], fromJS(values));
    } else {
      modifiers = this.state.modifiers.push(fromJS({ group, modifier, values }));
    }

    this.setState({ modifiers }, () => {
      this.ensureValidModifiers();
    });
  };

  ensureValidModifiers = () => {
    if (this.props.browseOnly) return false;
    const ems = { ...this.state.erroringModifiers };

    this.props.productModifiers.valueSeq().forEach(modifier => {
      if (modifier.get('minimum')) {
        const modifierValue = this.state.modifiers.find(
          m => m.getIn(['modifier', 'id']) === modifier.get('id')
        );
        if (!modifierValue?.get('values') || modifierValue.get('values').size < modifier.get('minimum')) {
          ems[modifier.get('id')] = [
            <FormattedMessage {...messages.error_minimum} values={{ minimum: modifier.get('minimum') }} />,
          ];
        } else {
          delete ems[modifier.get('id')]; // remove from errors once minimum is met
        }
      } else if (
        modifier.get('required') &&
        !this.state.modifiers.find(m => m.getIn(['modifier', 'id']) === modifier.get('id'))
      ) {
        ems[modifier.get('id')] = [<FormattedMessage {...messages.error_required} />];
      } else {
        delete ems[modifier.get('id')];
      }
    });

    this.setState(state => ({ ...state, erroringModifiers: ems }));
    return Object.keys(ems).length === 0;
  };

  addToOrder = () => {
    if (!this.ensureValidModifiers()) return false;

    const { source, popSheetInfoAndCustomisation, closeAlert, add, backToItemMenuPage, item, product } =
      this.props;

    if (source === 'customiseFromOnInfo') {
      popSheetInfoAndCustomisation();
      backToItemMenuPage(item);
    } else closeAlert();

    reactPixel.track('AddToCart', {
      content_name:
        product?.get('translations').get(this.props.currentLocale)?.get('name') ?? product?.get('name', ''),
    });
    gtmDataLayerPush('AddToCart', {
      content_name:
        product?.get('translations').get(this.props.currentLocale)?.get('name') ?? product?.get('name', ''),
    });

    add(this.state.quantity, this.state.modifiers, this.state.notes);
  };

  render() {
    const {
      item,
      product,
      closeAlert,
      editing,
      selectSchedule,
      allowItemNotes,
      productModifiers,
      browseOnly,
    } = this.props;
    const { showError, errors, modifierInfo, erroringModifiers } = this.state;

    if (
      typeof product === 'undefined' ||
      typeof product.get('modifier_groups') === 'undefined' ||
      productModifiers.isEmpty()
    )
      return <Loading />;

    const isDonation = Boolean(item.get('key', false));
    const selectedValues = modifierInfo ? this.getSelectedModifierValues(modifierInfo.modifier) : null;

    const items = productModifiers.valueSeq().map(modifier => {
      const required = modifier.get('required') || modifier.get('minimum');
      const errors = erroringModifiers[modifier.get('id')];

      return {
        initialOpenState: required,
        children: (
          <Modifier
            modifier={modifier}
            values={this.getSelectedModifierValues(modifier)}
            schedule={selectSchedule(product.get('scheduleId'))}
            hasError={errors?.length > 0}
            errors={errors}
            updateValues={values => this.updateModifierValues(modifier, values)}
            showModifierInfo={option => {
              console.log('showModifierInfo value', option);
              this.setState({ modifierInfo: { modifier, option } });
            }}
            taxCategories={product.get('tax_categories')}
            browseOnly={!!browseOnly}
            accordionOption={true}
          />
        ),
        title: (
          <OptionLabel
            name={modifier.get('display_name')}
            browseOnly={!!browseOnly}
            required={required}
            hasError={errors?.length > 0}
            minimum={modifier.get('minimum')}
            maximum={modifier.get('maximum')}
          />
        ),
      };
    });

    return (
      <>
        <AlertHeader>
          <AlertTitle>
            <FormattedMessage
              {...messages.product_title}
              values={{
                itemName:
                  product?.get('translations').get(this.props.currentLocale)?.get('name') ??
                  product?.get('name', ''),
              }}
            />
          </AlertTitle>
        </AlertHeader>
        {modifierInfo && (
          <ModifierProductInfo
            settings={modifierInfo.modifier}
            modifierValues={selectedValues}
            modifier={modifierInfo.option}
            taxCategories={product.get('tax_categories')}
            onCancel={() => this.setState({ modifierInfo: null })}
            updateValues={values => this.updateModifierValues(modifierInfo.modifier, values)}
            browseOnly={browseOnly}
          />
        )}
        {!modifierInfo?.modifier && (
          <>
            <AlertContent>
              {showError && !!errors.length && (
                <MessageBlock
                  header={<FormattedMessage {...messages.error_header} />}
                  body={
                    <ul>
                      {errors.map((error, index) => (
                        <li key={index}>{error}</li>
                      ))}
                    </ul>
                  }
                  type="error"
                />
              )}

              <Accordion items={items} />

              {!modifierInfo?.modifier && (
                <>
                  {allowItemNotes && !isDonation && (
                    <span>
                      <TextArea
                        label={<FormattedMessage {...productMessages.addNotes} />}
                        value={this.state.notes}
                        onChange={notes => this.setState({ notes })}
                      />
                    </span>
                  )}

                  <QuantityContainer>
                    <ProductQuantity
                      quantity={this.state.quantity}
                      onChange={quantity => this.setState({ quantity })}
                      productId={product?.get('id')}
                    />
                  </QuantityContainer>
                </>
              )}
            </AlertContent>
            <NavigationButtons
              editing={editing}
              cancel={closeAlert}
              browseOnly={browseOnly}
              addToOrder={this.addToOrder}
            />
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, { itemIndex, item, serviceId, menuType, editing, source }) => {
  const add = editing
    ? (quantity, modifiers, notes) => dispatch(updateItem(itemIndex, quantity, modifiers, notes))
    : (quantity, modifiers, notes) =>
        dispatch(addProductToOrder(item, menuType, serviceId, modifiers, notes, quantity, source));
  return {
    add,
    popSheetInfoAndCustomisation: () => dispatch(popSheetInfoAndCustomisation()),
    fetchProduct: id => dispatch(fetchProduct(id)),
  };
};

const mapStateToProps = (
  state,
  { itemIndex, item, serviceId = selectServiceId(state), showBaseModifiers = true }
) => {
  const props = {};

  if (itemIndex !== undefined) props.item = getOrderItemByIndex(state, itemIndex);

  const product = getProductById(
    state,
    itemIndex !== undefined ? props.item?.get('base_product_id') : item?.get('productId')
  );

  return {
    ...props,
    product,
    productModifiers: getProductModifiers(product, showBaseModifiers),
    selectSchedule: id => getScheduleById(state, id),
    backToItemMenuPage: item => backToItemMenuPage(state, item),
    allowItemNotes: shouldAllowItemNotes(state),
    browseOnly: isServiceBrowseOnly(state, serviceId),
    currentLocale: getLocale(state),
    getProductById: id => getProductById(state, id),
  };
};

const ConnectedProductOptins = connect(mapStateToProps, mapDispatchToProps)(ProductOptins);

export default ConnectedProductOptins;
