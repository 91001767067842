import React from 'react';
import { connect } from 'react-redux';
import { defineMessage, FormattedMessage } from 'react-intl';
import Input from '../../Input';
import { getDetail } from '../../../selectors/storage';
import { selectFulfilmentMethod, selectRoomChargeAllowedToChangeRoomNumber } from '../../../selectors/root';

const roomNumberLabel = defineMessage({
  defaultMessage: 'Room number',
});

interface Props {
  value: string;
  getRoomNumber: () => string;
  getFulfilmentMethodType: () => any;
  isAllowedToChangeRoomNumber: () => boolean;
  onChange: (value: string) => void;
}

const NumberInput: React.FC<Props> = ({
  value,
  getRoomNumber,
  getFulfilmentMethodType,
  isAllowedToChangeRoomNumber,
  onChange,
}: Props) => {
  const roomNumber = getRoomNumber();

  if (!isAllowedToChangeRoomNumber()) {
    if (roomNumber === undefined) return null;

    return (
      <div style={{ marginLeft: '5px' }}>
        <FormattedMessage
          defaultMessage="This order will be charged to room: { roomNumber }"
          values={{ roomNumber }}
        />
      </div>
    );
  }

  return (
    <>
      <Input type="text" value={value} label={roomNumberLabel.defaultMessage} onChange={onChange} />
      {getFulfilmentMethodType() === 'room' && roomNumber && (
        <div style={{ marginLeft: '5px' }}>
          <FormattedMessage
            defaultMessage="This order will be delivered to room: { roomNumber }"
            values={{ roomNumber }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  getRoomNumber: (): string => getDetail(state, 'roomNumber'),
  getFulfilmentMethodType: (): any => selectFulfilmentMethod(state).get('location_type'),
  isAllowedToChangeRoomNumber: (): boolean => selectRoomChargeAllowedToChangeRoomNumber(state),
});

export default connect(mapStateToProps)(NumberInput);
