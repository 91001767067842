import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

import OrderInTheKitchen from 'components/OrderInTheKitchen';
import SuccessIcon from 'components/Icons/SuccessIcon';
import { isLoggedIn, selectFirstName } from 'selectors/user';
import { kioskModeEnabled } from 'selectors/features';
import { getServiceThankYouMessage, isDineInOrderingFlow, selectVenueDefaultLocale } from 'selectors/root';
import {
  selectCompletedOrder,
  selectCompletedOrderFulfilment,
  selectCompletedOrderServiceId,
} from 'reducers/root';
import PageContainer from 'components/Pages/ThankYou/PageContainer';
import ThankYouHeaderMessage from 'components/Pages/ThankYou/ThankYouHeaderMessage';
import FulfilmentMessage from 'components/Pages/ThankYou/FulfilmentMessage';
import { useLoyalty, useSetTimeout } from 'hooks';
import { expireSession } from 'actions/order';
import { CAPABILITY_CALCULATE_POINTS } from 'actions/loyalty/constants';

import { RewardText } from 'components/Loyalty/EarnableLoyaltyPoints/styles';
import { selectLoyaltyTypeByService } from 'selectors/loyalty';
import { earnableError } from 'hooks/useLoyalty';
import { Register } from './Register';
import { OrderAgain } from './OrderAgain';

import messages from './messages';
import {
  CompleteIconContainer,
  OrderProcessing,
  StyledH1,
  StyledH2,
  OuterContainer,
  MessageContainer,
  SeparatorBlock,
  ActionMessage,
  StyledAuraLogo,
  LoyaltyLogoWrapper,
  LoyaltyTextContainer,
  LoyaltyInfoContainer,
} from './styles';

const propTypes = {
  kioskMode: PropTypes.bool,
  thankYouMessage: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isDineInOrderingFlow: PropTypes.bool,
  userName: PropTypes.string,
  orderFulfilment: PropTypes.instanceOf(Map),
  orderServiceId: PropTypes.string,
  expireSession: PropTypes.func,
  location: PropTypes.object,
};

const defaultProps = {
  isLoggedIn: false,
  kioskMode: false,
};

const ThankYou = ({
  expireSession,
  kioskMode,
  thankYouMessage,
  isLoggedIn,
  userName,
  isDineInOrderingFlow,
  orderFulfilment,
  orderServiceId,
  location,
}) => {
  useSetTimeout(() => {
    if (kioskMode) expireSession();
  }, 30000);

  const orderNumber = location?.query?.order_number;
  const fulfilment = {
    location_type: location?.query?.location_type,
    location: { ...location?.query },
  };

  const completedOrder = useSelector(selectCompletedOrder)?.toJS();

  const loyaltyType = useSelector(state => selectLoyaltyTypeByService(state, orderServiceId));

  const loyalty = useLoyalty();
  const loyaltyUser = loyalty?.user;
  const loyaltyEnabled = !!loyalty?.type && loyalty?.capabilities.includes(CAPABILITY_CALCULATE_POINTS);

  return (
    <PageContainer>
      {isDineInOrderingFlow ? (
        <OrderInTheKitchen />
      ) : (
        <OuterContainer>
          <CompleteIconContainer>
            <SuccessIcon width="60px" height="60px" />
          </CompleteIconContainer>
          <MessageContainer first={true}>
            {thankYouMessage && <StyledH1>{thankYouMessage}</StyledH1>}

            {!thankYouMessage && (
              <StyledH1>
                <FormattedMessage {...messages.thankYou} />
                {isLoggedIn && userName && ` ${userName}`}
              </StyledH1>
            )}

            <OrderProcessing>
              <ThankYouHeaderMessage orderNumber={orderNumber} />
            </OrderProcessing>
          </MessageContainer>

          {orderFulfilment?.size && <FulfilmentMessage fulfilment={fulfilment} serviceId={orderServiceId} />}

          {loyaltyEnabled && (
            <>
              <SeparatorBlock />
              <MessageContainer>
                <LoyaltyInfoContainer>
                  <LoyaltyLogoWrapper>
                    <StyledAuraLogo fullColor={true} />
                  </LoyaltyLogoWrapper>
                  <LoyaltyTextContainer>
                    {loyaltyType === 'aura' && earnableError && <FormattedMessage {...earnableError} />}
                    {(completedOrder?.points > 0 || loyaltyType !== 'como') && (
                      <FormattedMessage
                        defaultMessage="{
                      signedIn, select,
                        true {{
                          points, plural,
                          one {You have earned <green><b>#</b> point</green> on this purchase.}
                          other {You have earned <green><b>#</b> points</green> on this purchase.}
                        }}
                        other {{
                          points, plural,
                          =0 {You could have earned <green><b>Aura points</b></green> on this purchase.}
                          one {You could have earned <green><b>#</b> Aura point</green> on this purchase.}
                          other {You could have earned <green><b>#</b> Aura points</green> on this purchase.}
                        }}
                      }"
                        values={{
                          b: chunks => <strong>{chunks}</strong>,
                          green: chunks => <RewardText>{chunks}</RewardText>,
                          points: completedOrder?.points || 0,
                          signedIn: !!loyaltyUser,
                        }}
                      />
                    )}
                  </LoyaltyTextContainer>
                </LoyaltyInfoContainer>
              </MessageContainer>
            </>
          )}

          <OrderAgain
            serviceId={orderServiceId}
            kioskMode={kioskMode}
            onReturnClick={() => expireSession()}
          />

          {!isLoggedIn && !kioskMode && (
            <>
              <SeparatorBlock />
              <MessageContainer>
                <StyledH2>
                  <FormattedMessage {...messages.registerActionHeader} />
                </StyledH2>
                <ActionMessage>
                  <FormattedMessage {...messages.registerActionBody} />
                </ActionMessage>
                <Register isLoggedIn={isLoggedIn} />
              </MessageContainer>
            </>
          )}
        </OuterContainer>
      )}
    </PageContainer>
  );
};

ThankYou.propTypes = propTypes;
ThankYou.defaultProps = defaultProps;

export default connect(
  state => {
    const serviceId = selectCompletedOrderServiceId(state)?.toString();

    return {
      defaultLocale: selectVenueDefaultLocale(state),
      kioskMode: kioskModeEnabled(state),
      thankYouMessage: getServiceThankYouMessage(state, serviceId),
      isLoggedIn: isLoggedIn(state),
      isDineInOrderingFlow: isDineInOrderingFlow(state),
      userName: selectFirstName(state),
      orderFulfilment: selectCompletedOrderFulfilment(state),
      orderServiceId: serviceId,
    };
  },
  dispatch => ({
    expireSession: () => dispatch(expireSession()),
  })
)(ThankYou);
