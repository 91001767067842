import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { partition } from 'lodash';

import { defineMessages, FormattedMessage } from 'react-intl';
import ServiceLink from 'components/ServiceLink';
import ServiceImage from 'components/ServiceImage';
import ChevronBack from 'components/Icons/ChevronBack';
import { setMenuTypeId } from 'actions/browse';
import { filterServicesByCategory } from 'components/Pages/Browse/filterCategoryServices';
import { selectMenuTypeId, selectServicesForMenuType } from 'selectors/browse';
import { selectCompletedOrder } from 'reducers/root';
import { addCssPrefixTo } from 'utils';
import serviceHasImage from './serviceHasImage';

import { Wrapper, BackButton, VenuesTitle, ServiceSelectionHeader, ServiceSelectIntro } from './styles';
import { trackEvent, setUserProperties } from '../../utils/tracking';
import { getIsAdmin } from '../../selectors/root';

const messages = defineMessages({
  orderingFrom: {
    defaultMessage: 'Where are you ordering from?',
  },
  lastOrderedFrom: {
    defaultMessage: 'Last ordered from:',
  },
  otherLocations: {
    defaultMessage: 'Other locations:',
  },
  noServices: {
    defaultMessage: 'There are no services available at this venue.',
  },
  noServicesPublished: {
    defaultMessage: 'To order from this venue you will need to scan a QR code.',
  },
});

const propTypes = {
  services: PropTypes.array,
  lastOrderedService: PropTypes.object,
  serviceShowsAddress: PropTypes.bool,
  backLink: PropTypes.string,
  setMenuTypeId: PropTypes.func,
};

const ServiceSelection = ({ services, lastOrderedService, serviceShowsAddress, backLink, setMenuTypeId }) => {
  const isAdmin = useSelector(getIsAdmin);
  const servicesToDisplay = services.filter(service => isAdmin || service.publish_status === 'published');
  const draftServices = services.filter(service => service.publish_status === 'draft');

  console.log(servicesToDisplay, draftServices);

  const totalServices = servicesToDisplay.length + (lastOrderedService ? 1 : 0);

  useEffect(() => {
    if (totalServices === 1) {
      if (lastOrderedService) {
        browserHistory.replace(`/service/${lastOrderedService.id}`);
      } else {
        browserHistory.replace(`/service/${services[0]?.id}`);
      }
    }
  }, [services, lastOrderedService]);

  const showImages = servicesToDisplay.find(serviceHasImage);

  return (
    <Wrapper>
      <BackButton>
        <Link to={backLink || '/'}>
          <ChevronBack />
        </Link>
      </BackButton>
      <VenuesTitle className={`primaryColor ${addCssPrefixTo('ORDERING_FROM')}`}>
        <FormattedMessage {...messages.orderingFrom} />
      </VenuesTitle>
      <ServiceSelectIntro>
        {servicesToDisplay.length === 0 &&
          (draftServices.length > 0 ? (
            <FormattedMessage {...messages.noServicesPublished} />
          ) : (
            <FormattedMessage {...messages.noServices} />
          ))}
      </ServiceSelectIntro>
      {lastOrderedService && (
        <>
          <ServiceSelectionHeader>
            <FormattedMessage {...messages.lastOrderedFrom} />
          </ServiceSelectionHeader>
          <Link
            to={`/service/${lastOrderedService.id}`}
            onClick={() => {
              setUserProperties({
                service: lastOrderedService.id,
              });
              if (lastOrderedService.menu_types.length === 1)
                setMenuTypeId(lastOrderedService.menu_types[0].id);
            }}
          >
            <ServiceLink
              service={lastOrderedService}
              showAddress={serviceShowsAddress}
              image={showImages && <ServiceImage src={lastOrderedService.default_image} />}
            />
          </Link>
        </>
      )}
      {servicesToDisplay && (
        <>
          {lastOrderedService && (
            <ServiceSelectionHeader>
              <FormattedMessage {...messages.otherLocations} />
            </ServiceSelectionHeader>
          )}
          {servicesToDisplay.map(service => (
            <Link
              key={service.id}
              to={`/service/${service.id}`}
              onClick={() => {
                trackEvent('select_service', {
                  category: 'service',
                  service: service.id,
                });

                setUserProperties({
                  service: service.id,
                });

                if (service.menu_types.length === 1) setMenuTypeId(service.menu_types[0].id);
              }}
            >
              <ServiceLink
                service={service}
                showAddress={serviceShowsAddress}
                image={showImages && <ServiceImage src={service.default_image} />}
              />
            </Link>
          ))}
        </>
      )}
    </Wrapper>
  );
};

ServiceSelection.propTypes = propTypes;

const mapStateToProps = (state, { params }) => {
  const menuTypeId = selectMenuTypeId(state);
  const allServices = selectServicesForMenuType(state, menuTypeId);

  if (params?.categoryId) {
    const servicesInCategory = filterServicesByCategory(Number(params.categoryId), allServices);

    return { services: servicesInCategory, backLink: '/service-categories' };
  }

  const [[lastOrderedService], services] = partition(
    allServices,
    service => selectCompletedOrder(state).get('service') === Number(service.id)
  );

  return {
    lastOrderedService,
    services,
  };
};

export default connect(mapStateToProps, { setMenuTypeId })(ServiceSelection);
