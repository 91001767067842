import { fromJS, Map, List } from 'immutable';
import { browserHistory } from 'react-router';

import {
  getServiceById,
  getTaxCategoryById,
  getPreSelectedService,
  getIsStaff,
  getServiceId,
  selectServices,
} from 'selectors/root';
import { selectProductsInCartIds } from 'selectors/order';
import { selectSetAllergenFilters, selectSetLifestyleFilters } from 'selectors/allergenFilter';
import { isStockManaged, shouldShowPriceExcludingTax } from 'selectors/features';
import {
  filterServicesByCategory,
  filterActiveCategoriesByServices,
} from 'components/Pages/Browse/filterCategoryServices';
import { REPORTING_CAT_BEVERAGES, REPORTING_CAT_FOOD } from 'appConstants';
import { getLocale } from 'selectors/user';

export const menuNoLongerActive = menu => !menu.get('active') && !menu.get('nextActiveTime');

/**
 * Browse state selectors
 */

export const isMenuActive = (state, menuId, serviceId) => {
  // is menu active for menu and service schedules
  const menu = getMenuById(state, menuId);
  if (!menu) return false;
  if (!serviceId) return menu.get('active');
  return isServiceActiveById(state, serviceId) && menu.get('active');
};

export const isMenuAvailable = (state, menuId, serviceId) => {
  // is menu active and enabled
  const menu = getMenuById(state, menuId);
  if (!menu) return false;
  return menu.get('enabled') && isMenuActive(state, menuId, serviceId);
};

export const isMenuVisible = (state, menuId, serviceId) => {
  const menu = getMenuById(state, menuId);
  if (!menu || !serviceId) return false;
  const service = getServiceById(state, serviceId);

  return (
    menu.get('enabled') &&
    !hideStaffMenu(state, menu.get('id')) &&
    menuIsOnService(menu, serviceId) &&
    !menuNoLongerActive(menu) &&
    menuHasProducts(state, menu, service)
  );
};

export const hideStaffMenu = (state, menuId) => {
  const menu = getMenuById(state, menuId);
  return menu?.get('staffOnly') && !getIsStaff(state);
};

export const getMenuNextActiveDetails = (state, menuId, serviceId) => {
  const service = getServiceById(state, serviceId);
  const menu = getMenuById(state, menuId);
  if (!menu || !(service.get('nextActiveTime') || menu.get('nextActiveTime'))) return false;

  return {
    time: service.get('nextActiveTime') || menu.get('nextActiveTime'),
    date: service.get('nextActiveDate') || menu.get('nextActiveDate'),
    type: service.get('nextActiveTime') ? 'service' : 'menu',
  };
};

export const isServiceActiveById = (state, serviceId) => {
  const service = getServiceById(state, serviceId);
  return service && service.get('active');
};

export const getServiceNextActiveTime = (state, serviceId) => {
  const service = getServiceById(state, serviceId);
  return service && service.get('nextActiveTime');
};

export const menuIsLoading = state => state.getIn(['browse', 'isLoading']);

export const sectionIsLoading = state => state.getIn(['browse', 'sectionIsLoading']);

export const selectMenuTypes = state => state.getIn(['browse', 'data', 'menuTypes'], new List());

export const selectMenuTypeById = (state, id) => {
  const menuTypes = selectMenuTypes(state);
  return menuTypes.find(menuType => menuType.get('id') === parseInt(id, 10)) || new Map();
};

export const selectMenus = state => state?.getIn(['browse', 'data', 'menus']);

export const selectVisibleMenus = (state, serviceId) => {
  const allMenus = selectMenus(state) || new Map();
  return allMenus.filter(menu => isMenuVisible(state, menu.get('id'), serviceId));
};

export const selectProducts = state => state.getIn(['browse', 'data', 'products'], new Map());

export const getProductById = (state, productId) =>
  selectProducts(state).get(productId?.toString(), new Map());

export const noFoodInCart = state => {
  const productsInCartIds = selectProductsInCartIds(state);

  return !productsInCartIds
    .map(id => getProductById(state, id).get('reporting_category'))
    .some(reportingId => reportingId === REPORTING_CAT_FOOD);
};

export const getMenuTypes = (state, menuId) => {
  const menus = selectMenus(state);
  if (!menus) return false;

  const menu = menus.find((menu, id) => menuId === id);
  return !!menu && menu.get('types');
};

export const menuHasMenuType = (state, menuId, menuTypeId) => {
  const menuTypes = getMenuTypes(state, menuId);
  return !!menuTypes && !!menuTypes.find(id => id === menuTypeId);
};

export const menuIsOnService = (menu, serviceId) => {
  if (!menu.get('services') || (menu.get('services') && menu.get('services').size === 0)) return true;
  return !!menu.get('services').find(menuService => menuService === parseInt(serviceId, 10));
};

export const menusOnService = (menus, serviceId) => menus.filter(menu => menuIsOnService(menu, serviceId));

export const selectChildSections = (state, menuId, sectionId, serviceId) => {
  if (!menuId || !sectionId) return new List();
  const menu = getMenuById(state, menuId);
  const service = serviceId && getServiceById(state, serviceId);
  return menu && menu.get('sections')
    ? menu
        .get('sections')
        .filter(
          childSection =>
            sectionHasProducts(state, childSection, service) &&
            childSection.get('parent_id') === parseInt(sectionId, 10)
        )
        .toList()
        .sortBy(section => section.get('sort_order'))
    : new List();
};

export const selectCurrentSectionAndChildSections = (state, menuId, sectionId, serviceId) => {
  const section = getMenuSectionById(state, menuId, sectionId);
  const childSections = selectChildSections(state, menuId, sectionId, serviceId);

  return [...childSections, section].sort((a, b) => a.get('sort_order') - b.get('sort_order'));
};

export const getProductBreadcrumbs = (state, menuId, sectionId) => {
  const menu = getMenuById(state, menuId);
  const section = getMenuSectionById(state, menuId, sectionId);
  const menuName = menu?.get('translations')?.get(getLocale(state))?.get('name') ?? menu?.get('name');
  const sectionName = section?.get('name');
  let parentSection;
  if (section?.get('parent_id')) {
    parentSection = getMenuSectionById(state, menuId, section.get('parent_id'))?.get('name');
  }
  const breadcrumbs = [menuName, parentSection, sectionName];
  return breadcrumbs.filter(Boolean);
};

export const getProductStockQuantity = (state, productId) => {
  const stockManaged = isStockManaged(state);
  if (!stockManaged) return null;
  const product = getProductById(state, productId);
  return product?.get('quantity_in_stock', null);
};

export const productOutOfStock = (state, productId) => {
  const stockManaged = isStockManaged(state);
  const product = getProductById(state, productId);
  return stockManaged && product?.get('quantity_in_stock') === 0;
};

export const productIsEnabled = (product, service) => {
  if (!product || !product.size) return true; // if menu doesn't include product details just return true
  if (!product.get('enabled') || product.get('snoozed')) return false;
  if (service && service.get('epos_integrated') && !product.get('has_epos_link')) return false;
  if (!product.get('services') || product.get('services').size === 0) return true;
  return product.get('services').contains(parseInt(service.get('id'), 10));
};

export const itemIsEnabled = (state, item, service) => {
  const product = getProductById(state, item.get('productId'));
  return item.get('enabled') && productIsEnabled(product, service);
};

export const selectSectionHeaderImage = (state, sectionId, menuId) => {
  const section = getMenuSectionById(state, menuId, sectionId);
  const sectionImage = section?.getIn(['images', 0, 'path']);
  if (sectionImage) return sectionImage;
  const menu = getMenuById(state, menuId);
  if (section?.get('parent_id')) {
    const parentSection = getMenuSectionById(state, menuId, section.get('parent_id'));
    if (parentSection?.getIn(['images', 0, 'path'])) return parentSection?.getIn(['images', 0, 'path']);
  }
  return menu?.getIn(['images', 0, 'path']);
};

export const selectSectionProducts = (state, section, service) => {
  if (!section) return new List();

  return section
    .get('section_products')
    .filter(item => itemIsEnabled(state, item, service))
    .sortBy(item => item.get('id'))
    .sortBy(item => item.get('sort_order'))
    .sortBy(item => productIsFiltered(state, item.get('productId')));
};

export const sectionHasProducts = (state, section, service) =>
  !!selectSectionProducts(state, section, service).size;

export const isParentSection = (parent, menu) =>
  menu
    .get('sections')
    .reduce((carry, section) => carry || section.get('parent_id') === parent.get('id'), false);

export const selectSectionsWithProducts = (state, menu, service) => {
  if (!menu?.size || !menu.get('sections').size) return new List();
  return menu
    .get('sections')
    .filter(
      section =>
        isParentSection(section, menu) ||
        (section.get('parent_id') === null && sectionHasProducts(state, section, service))
    )
    .toList()
    .sortBy(section => section.get('sort_order'));
};

export const selectSections = (state, menu, service) =>
  selectSectionsWithProducts(state, menu, service).sortBy(section => sectionIsFiltered(state, section));

export const menuHasProducts = (state, menu, service) => {
  if (!menu.get('sections').size) return false;
  return !!menu.get('sections').find(section => sectionHasProducts(state, section, service));
};

export const selectMenusByType = (state, menuTypeId, serviceId) => {
  if (!menuTypeId || !serviceId) return new Map();

  return selectVisibleMenus(state, serviceId)
    .filter(menu => menu.get('types').find(id => id === parseInt(menuTypeId, 10)))
    .sortBy(menu => menu.get('sort_order'))
    .sortBy(menu => menuIsFiltered(state, menu))
    .sortBy(menu => (menu.get('active') ? 0 : 1));
};

export const selectActiveMenus = (state, menuTypeId, serviceId) => {
  if (selectMenus(state) === undefined) return undefined;
  return selectMenus(state).filter(menu => {
    if (!isMenuAvailable(state, menu.get('id'), serviceId) || !menuIsOnService(menu, serviceId)) return false;
    return menu
      .get('types')
      .reduce((hasType, typeId) => hasType || typeId === parseInt(menuTypeId, 10), false);
  });
};

export const selectVenueCategoriesDetails = state => state.getIn(['venue', 'service_categories']);

export const selectNormalizedServices = state => {
  const fullCategoriesDetails = selectVenueCategoriesDetails(state);
  const services = selectServices(state);
  const normalisedServices = services.map(service => {
    const normalisedServiceCategories =
      service.get('categories') &&
      service.get('categories').map(id => fullCategoriesDetails.find(cat => cat.get('id') === id));
    return service.set('categories', normalisedServiceCategories);
  });
  return normalisedServices.toList().toJS();
};

const getServiceMenuTypes = (state, service) =>
  service.fulfilment_methods &&
  service.fulfilment_methods
    .map(method => method.menuTypeIds)
    .reduce((arr, menuTypesList) => arr.concat(menuTypesList), [])
    .reduce((menuTypes, menuTypeId) => {
      if (!menuTypes.find(type => type.id === menuTypeId)) {
        const menuType = selectMenuTypeById(state, menuTypeId);
        menuTypes.push(menuType.toJS());
      }
      return menuTypes;
    }, []);

export const selectServicesForMenuType = (state, menuTypeId = null) => {
  const services = selectNormalizedServices(state)
    .sort((a, b) => a.sort_order - b.sort_order)
    .map(service => ({ ...service, menu_types: getServiceMenuTypes(state, service) }));

  if (!menuTypeId) return services;

  return services.filter(
    service => !menuTypeId || (menuTypeId && service.menu_types.find(menuType => menuType.id === menuTypeId))
  );
};

export const menusAreFiltered = (state, menuTypeId, serviceId) =>
  selectActiveMenus(state, menuTypeId, serviceId).some(menu => menuIsFiltered(state, menu));

export const menuHasFilteredSections = (state, menu) =>
  menu?.get('sections').some(section => sectionIsFiltered(state, section));

export const menuIsFiltered = (state, menu) =>
  !!menu.get('sections').size &&
  !menu.get('sections').filter(section => !sectionIsFiltered(state, section)).size;

export const sectionHasFilteredProducts = (state, section) =>
  section.get('section_products').some(item => productIsFiltered(state, item.get('productId')));

export const sectionIsFiltered = (state, section) =>
  !!section.get('section_products').size &&
  !section.get('section_products').filter(item => !productIsFiltered(state, item.get('productId'))).size;

export const productIsFiltered = (state, productId) => {
  const product = getProductById(state, productId);
  if (!product.size) return false;
  const allergens = product.get('allergens');

  const allergenFilters = selectSetAllergenFilters(state);
  const allergenFiltersAreSet = allergenFilters && allergenFilters.length;

  if (allergenFiltersAreSet && allergens) {
    const hiddenByAllergen = allergenFilters.reduce(
      (hidden, filterKey) => (hidden === true ? true : allergens.includes(filterKey) === true),
      false
    );
    if (hiddenByAllergen) return true;
  }

  const lifestyleFilters = selectSetLifestyleFilters(state);
  const lifestyleFiltersAreSet = lifestyleFilters && lifestyleFilters.length;

  if (lifestyleFiltersAreSet) {
    return lifestyleFilters.reduce(
      (hidden, filterKey) => (hidden === true ? true : !product.get(filterKey)),
      false
    );
  }

  return false;
};

export const productIsFoodOrBeverage = product =>
  [REPORTING_CAT_BEVERAGES, REPORTING_CAT_FOOD].includes(product.get('reporting_category'));

export const getMenuById = (state, menuId) =>
  typeof selectMenus(state) === 'undefined'
    ? undefined
    : selectMenus(state).find((menu, id) => menuId && id === menuId.toString());

export const getMenuSectionById = (state, menuId, sectionId) => {
  sectionId = sectionId?.toString();
  return typeof getMenuById(state, menuId) === 'undefined'
    ? undefined
    : getMenuById(state, menuId).getIn(['sections', sectionId]);
};

export const getScheduleById = (state, id) =>
  state.getIn(['browse', 'data', 'schedules']).find(schedule => schedule.get('id') === id) || new Map();

export const getBackButton = state => state.getIn(['browse', 'backButton']);

export const selectBrowseInfoTitle = state => state.getIn(['browse', 'browseInfo', 'title'], '');
export const selectBrowseInfoDescription = state => state.getIn(['browse', 'browseInfo', 'description'], '');
export const selectBrowseInfoImagePath = state => state.getIn(['browse', 'browseInfo', 'imagePath'], '');

export const selectDefaultBackButton = () => ({ text: 'home', href: '/' });

export const selectGetStartedLink = (state, menuTypeId = null) => {
  let services = selectServicesForMenuType(state, menuTypeId);
  const serviceCategories = filterActiveCategoriesByServices(services);
  let link = serviceCategories.length > 1 ? '/service-categories' : '/services';
  const preSelectedService = getPreSelectedService(state);
  if (preSelectedService) services = services.filter(service => service.id === preSelectedService);
  link = services.length === 1 ? `/service/${services[0].id}` : link;
  return link;
};
export const selectGetStartedServiceId = (state, menuTypeId = null) => {
  let services = selectServicesForMenuType(state, menuTypeId);
  const preSelectedService = getPreSelectedService(state);
  if (preSelectedService) services = services.filter(service => service.id === preSelectedService);
  const service = services.length === 1 ? services[0].id : undefined;
  return service;
};
export const selectMenuSelectionBackButton = (state, serviceId) => {
  const services = selectServicesForMenuType(state, selectMenuTypeId(state));
  const service = getServiceById(state, serviceId);
  if (service.size) {
    if (!service.get('categories').size && services.length > 1) {
      return { text: 'services', href: '/services' };
    }
    if (service.get('categories').size) {
      const servicesInCategory = filterServicesByCategory(service.getIn(['categories', 0]), services);
      if (servicesInCategory.length === 1) {
        return { text: 'categories', href: '/service-categories' };
      }
      return {
        text: 'services',
        href: `/service-categories/${service.getIn(['categories', 0]).toString()}`,
      };
    }
  }
  return selectDefaultBackButton();
};

export const selectMenuBackButton = (state, serviceId) => {
  const menuTypeId = selectMenuTypeId(state);
  const menus = selectMenusByType(state, menuTypeId, serviceId);
  if (menus && menus.size > 1) return { text: 'menus', href: `/service/${serviceId}` };
  return selectMenuSelectionBackButton(state, serviceId);
};

export const selectSectionBackButton = (state, serviceId, menuId, sectionId) => {
  const menu = getMenuById(state, menuId);
  const sections = selectSectionsWithProducts(state, menu, getServiceById(state, serviceId));
  if (menu.getIn(['sections', sectionId, 'parent_id']))
    return {
      text: 'section',
      href: `/service/${serviceId}/menu/${menuId}/section/${menu.getIn([
        'sections',
        sectionId,
        'parent_id',
      ])}`,
    };
  if (sections.size > 1) return { text: 'menu', href: `/service/${serviceId}/menu/${menuId}/` };
  return selectMenuBackButton(state, serviceId);
};

export const selectProductBackButton = (state, serviceId, menuId, sectionId) => ({
  text: 'section',
  href: `/service/${serviceId}/menu/${menuId}/section/${sectionId}`,
});

export const selectItemBackButton = (state, item) => {
  const serviceId = getServiceId(state);
  return `/service/${serviceId}/menu/${item.get('menu_id')}/section/${item.get('section_id')}`;
};

export const backToItemMenuPage = (state, item) =>
  browserHistory.push({
    pathname: selectItemBackButton(state, item),
    state: { transition: 'Back' },
  });

export const productHasModifiers = (product, exclBaseModifier = false) => {
  const modifierGroups = product?.get('modifier_groups');

  if (modifierGroups !== undefined && !modifierGroups.isEmpty()) {
    const hasModifiers = modifierGroups.some(modifierGroup => !modifierGroup.get('modifiers').isEmpty());
    if (hasModifiers && exclBaseModifier) {
      const groups = modifierGroups.filter(modifierGroup =>
        modifierGroup.get('modifiers').find(modifier => modifier.get('display_type') !== 'base')
      );
      return !!groups.size;
    }
    return hasModifiers;
  }
  return false;
};

export const showInstantAddButton = product => {
  const modifierGroups = product.get('modifier_groups');

  if (typeof modifierGroups === 'undefined') {
    return true;
  }

  let showButton = true;

  modifierGroups.forEach(modifierGroup =>
    modifierGroup.get('modifiers').forEach(modifier => {
      const required = modifier.get('required');
      const hasDefaultValue = modifier.get('values').some(value => value.get('default_value'));
      if (required && !hasDefaultValue) showButton = false;
    })
  );
  return showButton;
};

export const selectDefaultModifiers = product => {
  if (product?.get('modifier_groups')) {
    const modifiers = [];
    product.get('modifier_groups').map(modifierGroup =>
      modifierGroup.get('modifiers').forEach(modifier => {
        const values = [];
        modifier.get('values').forEach(value => {
          if (value.get('default_value')) {
            values.push(value.toJS());
          }
        });
        if (values.length) modifiers.push(fromJS({ group: modifierGroup.get('id'), modifier, values }));
      })
    );
    if (modifiers.length) {
      return fromJS(modifiers);
    }
    return undefined;
  }
};

export const getProductModifiers = (product, inclBaseModifier = false) => {
  if (!product.get('modifier_groups')) return new List();
  const modifiers = [];
  const modifierGroups = product.get('modifier_groups').sortBy(group => group.get('sort_order'));
  modifierGroups.map(modifierGroup =>
    modifierGroup
      .get('modifiers')
      .sortBy(group => group.get('sort_order'))
      .forEach(modifier => {
        if (modifier.get('display_type') !== 'base' || inclBaseModifier)
          modifiers.push({ ...modifier.toJS(), groupId: modifierGroup.get('id') });
      })
  );

  return fromJS(modifiers);
};

export const getSelectedBaseModifierProduct = modifiers => {
  const baseModifier = modifiers && modifiers.find(mod => mod.getIn(['modifier', 'display_type']) === 'base');
  return baseModifier?.getIn(['values', 0, 'product']);
};

export const selectMenuTypeId = state => state.getIn(['browse', 'menuTypeId']) || undefined;

export const selectMenuType = state => selectMenuTypeById(state, selectMenuTypeId(state));

export const selectServiceId = state => state.getIn(['browse', 'serviceId']);
export const selectMenuId = state => state.getIn(['browse', 'menuId']);

export const selectLowestAvailableMenuItemsForProducts = (state, products, menuTypeId, serviceId) => {
  if (!products || !menuTypeId || !serviceId) return new List();
  return products.map(product =>
    selectLowestAvailableMenuItemForProduct(state, product, menuTypeId, serviceId)
  );
};

export const selectLowestAvailableMenuItemForProduct = (state, product, menuTypeId, serviceId) => {
  const activeMenus = selectActiveMenus(state, menuTypeId, serviceId);

  if (!activeMenus || !product) return undefined;

  const lowestMenuInformation = activeMenus.reduce((bestProduct, menu) => {
    const bestCurrentProduct = getBestPriceInSections(menu, product.get('id'), menuTypeId, serviceId);
    if (bestProduct) {
      if (findPrice(bestCurrentProduct, menuTypeId) < findPrice(bestProduct, menuTypeId)) {
        return bestCurrentProduct;
      }
      return bestProduct;
    }
    return bestCurrentProduct;
  }, undefined);
  if (lowestMenuInformation) {
    return lowestMenuInformation.set('product', product);
  }
  return undefined;
};

export const getBestPriceInSections = (menu, productId, menuTypeId, serviceId) => {
  if (!isInService(menu, serviceId)) return undefined;
  return menu.get('sections').reduce((foundSectionProduct, section) => {
    const newSectionProduct = section
      .get('section_products')
      .find(sectionProduct => sectionProduct.get('productId') === productId);

    const foundPrice = findPrice(newSectionProduct, menuTypeId);
    if (foundPrice && foundPrice) {
      if (foundSectionProduct) {
        const previousProductPrice = findPrice(foundSectionProduct, menuTypeId);
        if (foundPrice < previousProductPrice) return newSectionProduct;
        if (foundPrice > previousProductPrice) return foundSectionProduct;
      }
      return newSectionProduct;
    }
    return foundSectionProduct;
  }, undefined);
};

export const isInService = (menu, serviceId) => {
  if (
    menu &&
    (menu.get('services').size === 0 || menu.get('services').indexOf(parseInt(serviceId, 10)) !== -1)
  )
    return true;
  return false;
};

export const findPrice = (sectionProduct, menuTypeId) => {
  const sectionProductResult =
    sectionProduct && sectionProduct.get('prices').find(price => price.get('menu_type') === menuTypeId);
  if (sectionProductResult) {
    return sectionProductResult.get('price');
  }
  return undefined;
};

export const getItemPrice = (state, price, taxCategories = new List()) => {
  if (taxCategories && taxCategories.size && !shouldShowPriceExcludingTax(state)) {
    let taxTotal = 0;
    taxCategories.forEach(category => {
      const taxCategoryId = typeof category === 'object' ? category.get('id') : category;

      const taxCategory =
        category.get && category.get('rates') ? category : getTaxCategoryById(state, taxCategoryId);

      if (taxCategory && taxCategory.getIn(['rates', 0, 'included_in_price']) === false) {
        taxTotal += getTax(taxCategory.getIn(['rates', 0, 'amount']), price);
      }
    });

    return price + taxTotal;
  }
  return price;
};

export const getModifierPrice = (state, modifier, taxCategories = new List(), modifierDisplayType) => {
  modifier = fromJS(modifier);
  let price = modifier.get('price_adjustment');
  if (modifierDisplayType === 'base') {
    price = modifier.getIn(['product', 'price']);
    taxCategories = modifier.getIn(['product', 'tax_categories']);
  }

  const individualPrice = getItemPrice(state, price, taxCategories);

  return individualPrice && individualPrice * (modifier.get('quantity') || 1);
};

export const getModifiersPriceForTrackEvent = modifiers => {
  if (modifiers) {
    if (modifiers.size === 0) {
      return null;
    }
    const priceAdj = modifiers.flatMap(el => el.get('values')).map(el => el.getIn(['price_adjustment']));
    const modsPrice =
      priceAdj && priceAdj.size > 0
        ? priceAdj.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / 100
        : 0;
    return modsPrice;
  }

  return null;
};

export const getTax = (taxRate, basePrice) => Math.round(parseFloat(taxRate) * basePrice);

export const selectVenuePath = state => state.getIn(['venue', 'routes', 0, 'path'], false);

export const getProductAndModifiersFromItem = (state, item, showBaseModifiers) => {
  const product = getProductById(state, item.get('base_product_id') || item.get('productId'));

  let productModifiers = getProductModifiers(product, showBaseModifiers);

  // We're using a base modifier
  if (item.get('base_product_id') !== item.get('productId')) {
    const baseProduct = product
      .get('modifier_groups')
      ?.flatMap(group => group.get('modifiers', List()))
      .flatMap(modifier => modifier.get('values', List()))
      .map(value => value.get('product'))
      .find(product => product && product.get('id') === item.get('productId'), null, null);

    if (baseProduct) {
      const baseProductModifiers = getProductModifiers(baseProduct, showBaseModifiers);

      productModifiers = baseProductModifiers.concat(productModifiers ?? new List()).sort((a, b) => {
        if (a.get('display_type') === 'base' && b.get('display_type') !== 'base') {
          return -1;
        }
        if (a.get('display_type') !== 'base' && b.get('display_type') === 'base') {
          return 1;
        }
        return 0;
      });
    }
  }

  return { product, productModifiers };
};
