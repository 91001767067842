import styled from 'styled-components';
import { H1, H2 } from 'components/Typography';
import AuraLogo from 'components/Icons/AuraLogo';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  button,
  a {
    width: auto;
    margin: 5px;
  }
`;

export const Container = styled.div`
  line-height: normal;
`;

export const CompleteIconContainer = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.success};
  background-color: ${({ theme }) => theme.color.white};
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
`;

export const InnerSection = styled.section`
  height: 100%;
  overflow-x: hidden;
  position: relative;
`;

export const ContentContainer = styled.div`
  margin: 70px 20px 70px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 25px;
  padding: 40px 20px;
  text-align: center;
`;

export const ContentInner = styled.div`
  margin: 0 auto;
  max-width: 400px;
`;

export const OrderProcessing = styled.div`
  font-size: 1.1em;
`;

export const CustomConfirmationMessage = styled.p`
  margin: 5px 0;
`;

export const StyledH1 = styled(H1)`
  font-weight: bold;
  margin: 0 0 5px;
`;

export const StyledH2 = styled(H2)`
  margin: 0 0 15px;
`;

export const OuterContainer = styled.div`
  margin: 40px 20px 40px;
`;

export const MessageContainer = styled.div`
  margin-top: ${({ first }) => first && '-45px'};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 25px;
  padding: 20px 20px;
  padding-top: ${({ first }) => first && '50px'};
`;

export const SeparatorBlock = styled.div`
  margin: 0 25px;
  height: 2px;
  border-top: 2px dashed ${({ theme }) => theme.color.borderGray};
  background-color: ${({ theme }) => theme.color.white};
`;

export const ActionMessage = styled.p`
  margin-bottom: 20px;
`;

export const LoyaltyInfoContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  min-height: 3rem;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0.5rem;
`;

export const LoyaltyTextContainer = styled.div`
  margin-left: 10px;
  flex-grow: 1;
`;

export const StyledAuraLogo = styled(AuraLogo)`
  height: 40px;
  width: auto;
`;

export const LoyaltyLogoWrapper = styled.div`
  flex-shrink: 0;
  width: fit-content;
`;
