import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  emailReceiptMessage: {
    defaultMessage: 'Please enter your email if you would like to receive a receipt.',
  },
});

export default {
  addressLabel: {
    required: true,
    type: 'text',
    name: 'addressLabel',
    errorKey: 'fulfilment.location.addressLabel',
    addressDetail: true,
    storeDetail: false,
  },
  deliveryAddress: {
    type: 'SubHeader',
    intlIdentifier: 'deliveryAddress',
    sortOrder: 500,
  },
  addressNotes: {
    required: false,
    type: 'component',
    name: 'addressNotes',
    component: 'Notes',
    showLabel: true,
    addressDetail: true,
    storeDetail: true,
    radiusDetail: true,
  },
  addressPin: {
    required: false,
    type: 'text',
    name: 'addressPin',
    addressDetail: true,
    storeDetail: true,
    radiusDetail: true,
  },
  address1: {
    required: true,
    type: 'text',
    name: 'address1',
    errorKey: 'fulfilment.location.address1',
    addressDetail: true,
    storeDetail: true,
  },
  address2: {
    required: false,
    type: 'text',
    name: 'address2',
    addressDetail: true,
    storeDetail: true,
  },
  townCity: {
    required: true,
    type: 'text',
    name: 'town_city',
    errorKey: 'fulfilment.location.town_city',
    addressDetail: true,
    storeDetail: true,
  },
  county: {
    required: false,
    type: 'text',
    name: 'county',
    errorKey: 'fulfilment.location.county',
    addressDetail: true,
    storeDetail: true,
  },
  postcode: {
    type: 'text',
    name: 'postcode',
    errorKey: 'fulfilment.location.postcode',
    addressDetail: true,
    storeDetail: true,
  },
  block: {
    required: false,
    type: 'component',
    name: 'block',
    sortOrder: 500,
    errorKey: 'fulfilment.location.block',
    locationKey: 'blocks',
    component: 'LocationName',
    showLabel: true,
    storeDetail: true,
  },
  collectionPoint: {
    required: true,
    type: 'component',
    name: 'collectionPoint',
    intlIdentifier: 'collectionPointTitle',
    errorKey: 'fulfilment.location.collection_point',
    component: 'CollectionPoints',
    fulfilmentKey: 'point',
    storeDetail: true,
  },
  covers: {
    required: true,
    type: 'number',
    name: 'covers',
    errorKey: 'fulfilment.location.covers',
    storeDetail: true,
    sortOrder: 200,
    updateOrderOnBlur: true,
  },
  dropdown: {
    type: 'component',
    component: 'DropdownField',
    name: 'dropdown',
    options: [],
  },
  email: {
    type: 'email',
    name: 'email',
    userDetail: true,
    storeDetail: true,
    sortOrder: 200,
    optionalHelpMessage: <FormattedMessage {...messages.emailReceiptMessage} />,
  },
  name: {
    required: true,
    type: 'text',
    name: 'name',
    userDetail: true,
    storeDetail: true,
  },
  firstName: {
    type: 'text',
    name: 'firstName',
    userDetail: true,
    storeDetail: true,
  },
  lastName: {
    type: 'text',
    name: 'lastName',
    userDetail: true,
    storeDetail: true,
  },
  password: {
    type: 'password',
    name: 'password',
    userDetail: true,
    required: true,
    autoComplete: 'off',
  },
  newPassword: {
    type: 'component',
    name: 'newPassword',
    component: 'NewPassword',
    userDetail: true,
    required: true,
    showLabel: true,
    autoComplete: 'off',
  },
  confirmPassword: {
    type: 'password',
    name: 'confirmPassword',
    userDetail: true,
    required: true,
    autoComplete: 'off',
  },
  row: {
    required: false,
    type: 'text',
    name: 'row',
    storeDetail: true,
  },
  seat: {
    required: true,
    type: 'number',
    name: 'seat',
    errorKey: 'fulfilment.location.seat',
    storeDetail: true,
  },
  seatingDetails: {
    type: 'SubHeader',
    intlIdentifier: 'seatingDetails',
  },
  roomNumber: {
    required: true,
    type: 'component',
    name: 'roomNumber',
    errorKey: 'fulfilment.location.room_number',
    component: 'LocationName',
    locationKey: 'rooms',
    fulfilmentKey: 'room',
    showLabel: true,
    storeDetail: true,
    expiry: { format: 'hours', length: 3 },
    updateOrderOnBlur: true,
  },
  sunbedNumber: {
    required: true,
    type: 'component',
    name: 'sunbedNumber',
    expiry: { format: 'hours', length: 1 },
    errorKey: 'fulfilment.location.sunbed_number',
    component: 'LocationName',
    locationKey: 'sunbeds',
    fulfilmentKey: 'sunbed',
    showLabel: true,
    storeDetail: true,
    updateOrderOnBlur: true,
  },
  tableNumber: {
    required: true,
    type: 'component',
    name: 'tableNumber',
    expiry: { format: 'hours', length: 1 },
    errorKey: 'fulfilment.location.table_number',
    component: 'LocationName',
    locationKey: 'tables',
    fulfilmentKey: 'table',
    showLabel: true,
    storeDetail: true,
    updateOrderOnBlur: true,
  },
  telephone: {
    required: false,
    type: 'component',
    name: 'telephone',
    component: 'TelephoneField',
    userDetail: true,
    storeDetail: true,
  },
  fulfilmentDateTime: {
    required: true,
    type: 'component',
    name: 'fulfilmentDateTime',
    value: {},
    expiry: { format: 'hours', length: 1 },
    component: 'FulfilmentDateTime',
    sortOrder: 6000,
    errorKey: 'fulfilment.time',
    storeDetail: true,
  },
  notes: {
    required: false,
    type: 'component',
    name: 'notes',
    component: 'Notes',
    sortOrder: 8000,
    showLabel: true,
    storeDetail: true,
  },
  addressInRadius: {
    required: false,
    type: 'component',
    name: 'addressInRadius',
    component: 'AddressInRadius',
    sortOrder: 8100,
  },
  addressSelection: {
    required: true,
    type: 'component',
    name: 'addressSelection',
    component: 'AddressSelection',
    sortOrder: 8900,
  },
  donations: {
    required: false,
    type: 'component',
    name: 'donations',
    component: 'Donations',
    sortOrder: 8950,
    storeDetail: false,
  },
  voucher: {
    required: false,
    type: 'component',
    name: 'voucher',
    component: 'OrderVoucherField',
    sortOrder: 8952,
  },
  payment: {
    required: true,
    type: 'component',
    name: 'payment',
    component: 'Payment',
    sortOrder: 9500,
    storeDetail: true,
  },
  items: {
    required: false,
    type: 'component',
    name: 'items',
    component: 'OrderSummaryField',
    sortOrder: 9000,
  },
  terms: {
    required: true,
    type: 'component',
    name: 'terms',
    component: 'TermsCheckbox',
    sortOrder: 10000,
    validationMessage: 'notAcceptedTerms',
    storeDetail: true,
  },
  thirdPartyPermission: {
    type: 'component',
    name: 'thirdPartyPermission',
    component: 'ThirdPartyPermission',
    sortOrder: 11000,
  },
  fulfilmentNotes: {
    type: 'component',
    name: 'fulfilmentNotes',
    component: 'FulfilmentNotes',
    sortOrder: 7500,
  },
  carRegistration: {
    required: true,
    type: 'text',
    name: 'registration',
    storeDetail: true,
    errorKey: 'fulfilment.location.registration',
    fulfilmentKey: 'car',
  },
  bayNumber: {
    required: true,
    type: 'text',
    name: 'bay_number',
    storeDetail: true,
    sortOrder: 400,
    errorKey: 'fulfilment.location.bay_number',
  },
  carMake: {
    required: true,
    type: 'text',
    name: 'make',
    storeDetail: true,
    sortOrder: 400,
    errorKey: 'fulfilment.location.make',
  },
  carModel: {
    required: true,
    type: 'text',
    name: 'model',
    storeDetail: true,
    sortOrder: 400,
    errorKey: 'fulfilment.location.model',
  },
  carColour: {
    required: true,
    type: 'text',
    name: 'colour',
    storeDetail: true,
    sortOrder: 400,
    errorKey: 'fulfilment.location.colour',
  },
  passcode: {
    required: true,
    type: 'component',
    name: 'passcode',
    component: 'EnterPasscode',
  },
  allergenCheckbox: {
    required: true,
    type: 'component',
    name: 'allergenCheckbox',
    component: 'AllergenCheckbox',
    sortOrder: 10500,
  },
  consentCheckbox: {
    type: 'component',
    name: 'user_marketing_consent',
    component: 'ConsentCheckbox',
    sortOrder: 10500,
  },
  cutlery: {
    required: false,
    type: 'component',
    name: 'cutlery',
    component: 'Cutlery',
    sortOrder: 8975,
    storeDetail: false,
  },
  TabOrderSummary: {
    required: false,
    type: 'component',
    name: 'TabOrderSummary',
    component: 'TabOrderSummaryField',
    sortOrder: 8952,
  },
  loyalty: {
    required: false,
    type: 'component',
    name: 'CheckoutLoyalty',
    component: 'EarnableLoyaltyPoints',
    sortOrder: 8951,
  },
  loyaltySpend: {
    required: false,
    type: 'component',
    name: 'RedeemLoyaltyPoints',
    component: 'RedeemLoyaltyPoints',
    sortOrder: 8951,
  },
  roomChargeTitle: {
    type: 'component',
    component: 'RoomChargeTitle',
    sortOrder: 9600,
  },
  roomChargeName: {
    required: true,
    type: 'component',
    component: 'RoomChargeNameInput',
    name: 'roomChargeName',
    sortOrder: 9700,
    storeDetail: true,
  },
  roomChargeRoomReference: {
    required: true,
    type: 'component',
    component: 'RoomChargeNumberInput',
    name: 'roomChargeNumber',
    sortOrder: 9800,
    storeDetail: true,
  },
};
