import React from 'react';
import { Header, Title as HeaderTitle } from '../../Form/FormHeader/styles';

const Title: React.FC = () => (
  <Header>
    <HeaderTitle>Please enter the room charge details</HeaderTitle>
  </Header>
);

export default Title;
