import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import ActionButton from 'components/ActionButton';

import { ButtonsContainer } from './styles';

const messages = defineMessages({
  createAnAccount: {
    defaultMessage: 'Create An Account',
  },
});

export const Register = () => (
  <ButtonsContainer>
    <ActionButton
      primaryButton={true}
      to="/#register"
      label={<FormattedMessage {...messages.createAnAccount} />}
    />
  </ButtonsContainer>
);
