import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List, Set, Map, fromJS } from 'immutable';

import { isPreOrderDatesOnly, kioskModeEnabled } from 'selectors/features';
import {
  selectMenus,
  selectMenuTypeId,
  selectMenuTypeById,
  selectMenuId,
  selectServiceId,
} from 'selectors/browse';
import { selectOrderFulfilmentMethodId, selectOrderTotal, validateTimeSlot } from 'selectors/order';
import { selectSessionId } from 'selectors/session';
import { getLocale, selectUserDefaultLocale } from 'selectors/user';
import { convertDateToVenueTimezone } from 'utils/date';
import { ORDERING_FLOW_DINE_IN, LOCATION_TYPE_TABLE } from 'appConstants';
import { isInRange, urlTransform } from 'utils';

import allergenMessages from 'components/Alert/alerts/AllergenFilter/messages';
import fieldTypes from 'components/Form/fieldTypes';
import { getDetail } from 'selectors/storage';

export const getServiceId = state =>
  state.getIn(['browse', 'serviceId']) && state.getIn(['browse', 'serviceId']).toString();

const methodHasMenuType = (method, menuTypeId) =>
  method.get('menuTypeIds').reduce((hasId, id) => hasId || id === menuTypeId, false);

export const selectOrganisationName = state => state.getIn(['organisation', 'name']);

export const selectIsOrganisation = state => !state.get('venue') && !!state.get('organisation');
export const getVenueId = state => state.getIn(['venue', 'id']);
export const getVenueOrganisationId = state => state.getIn(['venue', 'organisation', 'id']);
export const getIsVenueOffline = state => state.getIn(['venue', 'offline']);
export const getVenueExcludedPhoneNumberCountries = state => {
  const countries = state.getIn(['venue', 'excluded_phone_number_countries']);
  if (!countries) return ['gg']; // default original behaviour
  return countries
    .toLowerCase()
    .split(',')
    .map(country => country.trim());
};

export const selectVenueName = state => state.getIn(['venue', 'name']);
export const getCustomFields = state => state.getIn(['venue', 'additional_fields']) || new List();
export const getLocales = state => state.getIn(['venue', 'locales']) || new List();
export const selectVenueDefaultLocale = state => state.getIn(['venue', 'default_locale', 'code']) || 'en_GB';
export const selectServices = state => state.getIn(['venue', 'services']) || new Map();
export const getVenueTaxCategories = state => state.getIn(['venue', 'tax_categories']) || new List();
export const getTaxCategoryById = (state, id) =>
  getVenueTaxCategories(state).find(category => category.get('id') === id) || null;
export const selectServicesPublished = state =>
  selectServices(state).filter(service => service.get('publish_status') === 'published');
export const getIsAdmin = state => state.getIn(['user', 'data', 'isAdmin']);
export const getIsStaff = state => state.getIn(['user', 'data', 'isStaff']);
export const selectServicesList = state =>
  getIsAdmin(state) === true ? selectServices(state) : selectServicesPublished(state);

export const selectService = state => getServiceById(state, getServiceId(state));

export const getServiceById = (state, id) => state.getIn(['venue', 'services', id?.toString()]) || new Map();

export const selectServiceName = (state, id) => {
  const localeTranslation = selectServiceMessageByLocale(state, id, 'name');
  if (localeTranslation) return localeTranslation;
  const venueLocale = selectVenueDefaultLocale(state);
  return selectServiceTranslations(state, id).getIn([venueLocale, 'name']);
};

export const selectServiceTranslations = (state, id) =>
  getServiceById(state, id).get('translations', new Map());

export const selectServiceMessageByLocale = (state, serviceId, msg) => {
  const userLocale = selectUserDefaultLocale(state) || getLocale(state);
  const serviceTranslations = selectServiceTranslations(state, serviceId);
  return serviceTranslations && serviceTranslations.getIn([userLocale, msg], false);
};

export const selectAllowCallTheWaiter = state => {
  const id = state.getIn(['global', 'completedOrder', 'service']);
  if (!id) return undefined;
  return getServiceById(state, id.toString()).get('allow_call_the_waiter');
};
export const getServiceThankYouMessage = (state, id) =>
  state.getIn(['venue', 'services', id, 'thankyou_page_message']);
export const getServiceOrderConfirmationMessage = (state, id) =>
  state.getIn(['venue', 'services', id, 'order_confirmation_message']);
export const serviceAcceptsOrderNotes = (state, id) =>
  state.getIn(['venue', 'services', id, 'accept_order_notes']);
export const getPreSelectedService = state => state.getIn(['global', 'preSelectedService']);
export const getCheckoutErrors = errorKey => state =>
  state.getIn(['payment', 'violations', errorKey, 'errors']);

export const getServiceMinimumGratuity = (state, serviceId) => {
  const service = getServiceById(state, serviceId);
  return service?.get('minimum_gratuity') || 0;
};

export const getServiceDefaultGratuity = (state, serviceId) => {
  const service = getServiceById(state, serviceId);
  return service?.get('default_gratuity') || getServiceMinimumGratuity(state, serviceId);
};

export const isServiceBrowseOnly = (state, serviceId) => {
  if (!serviceId) return false;

  const service = getServiceById(state, serviceId);
  return service?.get('browse_only') || false;
};

export const isDineInOrderingFlow = (state, serviceId = getServiceId(state)) => {
  if (!serviceId) return false;

  const service = getServiceById(state, serviceId);
  return service?.get('ordering_flow') === ORDERING_FLOW_DINE_IN || false;
};

export const getTableNumbersByServiceId = (state, id) => getServiceLocationNumbers(state, 'tables', id);

export const getFulfilmentFieldLabel = (state, fulfilmentKey) => {
  const fulfilmentMethods = selectAvailableFulfilmentMethods(state);
  const method =
    fulfilmentMethods.find(fulfilment => fulfilment.get('location_type') === fulfilmentKey) || new Map();

  const userLocale = selectUserDefaultLocale(state) || getLocale(state);

  return method.get('translations')?.getIn([userLocale, 'label']) || method.get('label');
};

export const getServiceLocationNumbers = (state, locationType, serviceId = getServiceId(state)) => {
  if (!serviceId) return new List();
  const service = getServiceById(state, serviceId.toString()) || new Map();
  return service.getIn([locationType, 'numbers']) || new List();
};

export const getServiceLocationNames = (state, locationType, serviceId = getServiceId(state)) => {
  if (!serviceId) return new List();
  const service = getServiceById(state, serviceId.toString()) || new Map();
  const names = service.getIn([locationType, 'names']) || new List();
  return names.map(name => name.get('value'));
};

export const isValidLocation = (state, locationKey, value, serviceId = getServiceId(state)) =>
  isNaN(value)
    ? isValidLocationName(state, locationKey, value, serviceId)
    : isValidLocationNumber(state, locationKey, value, serviceId);

export const isValidLocationName = (state, locationKey, value, serviceId) => {
  const names = getServiceLocationNames(state, locationKey, serviceId);
  if (!names.size) return true;
  return !!names.find(name => name.toLowerCase() === value.toLowerCase());
};

export const isValidLocationNumber = (state, locationKey, value, serviceId) => {
  const numbers = getServiceLocationNumbers(state, locationKey, serviceId);
  if (!numbers.size) return true;
  value = parseInt(value, 10);
  return !!numbers.find(number => {
    if (number.get('min') && number.get('max')) {
      return value >= number.get('min') && value <= number.get('max');
    }
    return value === number.get('value');
  });
};

export const getCollectionPointsByServiceId = (state, id) => {
  const service = id && getServiceById(state, id.toString());
  return service ? service.get('collection_points') : new List();
};

export const getAllowedCallTheWaiterServices = state => {
  const services = selectServices(state);
  return services.filter(service => service.get('allow_call_the_waiter')) || new Map();
};

export const selectEPOSIntegrated = (state, serviceId = getServiceId(state)) => {
  if (!serviceId) return false;

  const service = getServiceById(state, serviceId);
  return service?.get('epos_integrated') || false;
};

export const selectContentAreas = state => area => state.getIn(['venue', 'contentAreas', 'areas', area]);
export const selectLogoUrl = state => state.getIn(['branding', 'splash_logo_path']) || undefined;
export const selectAddToHomeScreenUrl = state =>
  state.getIn(['branding', 'home_screen_icon_path']) || undefined;

export const selectPaymentMethodCTAText = (state, paymentMethodKey) => {
  const paymentMethods = selectPaymentMethods(state);
  if (paymentMethods.size === 0) return undefined;
  return paymentMethods.find(method => method.get('key') === paymentMethodKey)?.get('cta_text') ?? undefined;
};

export const selectPaymentMethods = state => {
  const paymentMethods = state.getIn(['payment', 'paymentMethods'], new List());
  const serviceId = getServiceId(state);
  if (!serviceId) return paymentMethods;

  const result = paymentMethods.filter(method => {
    const serviceIdsList = method.get('service_ids');

    return serviceIdsList?.size === 0 ? true : serviceIdsList?.contains(Number(serviceId));
  });

  return result;
};
export const selectAvailablePaymentMethods = (state, orderTotal) => {
  const paymentMethods = selectPaymentMethods(state);
  const kioskMode = kioskModeEnabled(state);

  const filteredPaymentMethods = paymentMethods
    .filter(method => {
      const minAmount = method.get('min_amount');
      const maxAmount = method.get('max_amount');
      return isInRange(orderTotal, minAmount, maxAmount);
    })
    .filter(method => !!selectTabEnabledByServiceId(state) || method.getIn(['group', 'key']) !== 'tab')
    .filter(method => {
      if (typeof window === 'undefined' || !window?.ApplePaySession) {
        return method.getIn(['group', 'key']) !== 'apple_pay';
      }
      return true;
    });

  if (!kioskMode) return filteredPaymentMethods;

  // if kiosk mode, filter by kiosk mode enabled methods
  return filteredPaymentMethods.filter(method => !method.get('rules').toJS().includes('non_ordering_device'));
};

export const isAvailablePaymentMethodByKey = (
  state,
  paymentMethodKey,
  orderTotal = selectOrderTotal(state)
) => {
  const paymentMethods = selectAvailablePaymentMethods(state, orderTotal);
  return paymentMethods.find(paymentMethod => paymentMethod.get('key') === paymentMethodKey) !== undefined;
};

export const isOfflinePaymentMethod = (state, value) => {
  const paymentMethods = selectPaymentMethods(state);
  const [selectedMethod] = paymentMethods.filter(method => method.get('key') === value);

  return selectedMethod ? selectedMethod.get('offline_method') : false;
};

export const isTabPaymentMethod = (state, key) => {
  const paymentMethods = selectPaymentMethods(state);
  const [selectedMethod] = paymentMethods.filter(method => method.get('key') === key);
  return selectedMethod ? selectedMethod.getIn(['group', 'key']) === 'tab' : false;
};

export const selectVenueCurrencyCode = state => state.getIn(['venue', 'currency', 'code']);
export const selectVenueCurrencyDecimals = state => state.getIn(['venue', 'currency', 'decimals']);

export const selectAvailableFulfilmentMethods = state => {
  const serviceId = getServiceId(state);
  if (serviceId === undefined) return new List();
  const menuTypeId = selectMenuTypeId(state);
  const methods =
    state.getIn(['venue', 'services', serviceId.toString(), 'fulfilment_methods']) || new List();

  return methods.filter(method => methodHasMenuType(method, menuTypeId));
};

export const selectDineInSessionStorage = (state, serviceId = getServiceId(state)) => {
  if (!serviceId) return '';
  return getDetail(state, `sessionId_${serviceId}`, '') || '';
};

export const selectDineInPasscode = state => {
  const serviceId = getServiceId(state);
  if (!serviceId || !selectSessionId(state)) return '';
  return getDetail(state, `passcode_${serviceId}`, '') || '';
};

export const selectDineInPasscodeForConfirmationPage = state => {
  const serviceId = getServiceId(state);
  return getDetail(state, `passcode_${serviceId}`, '') || '';
};

export const selectDineInFulfilmentType = state => {
  const methods = selectAvailableFulfilmentMethods(state);

  const filteredMethods = methods.filter(method =>
    [LOCATION_TYPE_TABLE].includes(method.get('location_type'))
  );
  return filteredMethods?.size ? filteredMethods.first() : new Map();
};

export const selectDineInLocationField = state => {
  const locationType = selectDineInFulfilmentType(state)?.get('location_type');

  if (!locationType) return {};
  return Object.values(fieldTypes).find(field => field.fulfilmentKey === locationType) || {};
};

export const selectDineInLocationDetail = state => {
  const field = selectDineInLocationField(state);

  if (!field?.name) return '';
  const detail = getDetail(state, field?.name) || '';
  return isValidLocation(state, field?.locationKey, detail) ? detail : '';
};

export const selectTabEnabledOnAnyService = state =>
  selectServices(state).reduce((tabEnabled, service) => tabEnabled || service.get('allow_tab'), false);

export const selectFulfilmentMethod = state => {
  const methods = selectAvailableFulfilmentMethods(state);
  const fulfilmentMethodId = selectOrderFulfilmentMethodId(state);
  const menuTypeId = selectMenuTypeId(state);
  return methods.find(
    method => methodHasMenuType(method, menuTypeId) && method.get('id') === fulfilmentMethodId
  );
};

export const selectFulfilmentMethodId = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('fulfilment_method_id');
};

export const selectFulfilmentId = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('id');
};

export const selectFulfilmentMethodPreparationTime = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('preparation_time');
};

export const selectFulfilmentMethodAllowAsap = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('allow_asap', true);
};

export const selectFulfilmentMethodFields = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('fields');
};

export const isLoadingFulfilmentMethodTimeSlots = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  if (fulfilmentMethodHasAvailableTimeSlots(state)) return false;
  return fulfilmentMethod && fulfilmentMethod?.get('loadingTimeslots', false);
};

export const selectPreorderEnabled = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('show_timeslots', false);
};

export const selectRoomChargeAllowedToChangeRoomNumber = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);

  if (!fulfilmentMethod) {
    return false;
  }

  return !fulfilmentMethod.get('restrict_room_charge');
};

export const getFilteredTimeSlots = (state, timeslots = new Map()) => {
  if (!timeslots || !timeslots.size) return new Map();
  const venueTimezone = selectVenueTimezone(state);
  const venueDate = convertDateToVenueTimezone(new Date(), venueTimezone);
  venueDate.setHours(0, 0, 0, 0);
  timeslots.forEach((value, key) => {
    const timeslotDate = new Date(`${key} 00:00:00`);
    if (venueDate.getTime() > timeslotDate.getTime()) {
      timeslots = timeslots.delete(key);
    }
    if (venueDate.getTime() === timeslotDate.getTime()) {
      // check first timeslot that's not ASAP. If valid, don't validate the rest of the timeslots
      const firstTimeSlot = value.find(timeslot => timeslot.get('start') !== 'ASAP');
      if (!firstTimeSlot) return;
      const isFirstTimeslotValid = !!validateTimeSlot(
        state,
        firstTimeSlot.get('start'),
        firstTimeSlot.get('date')
      );
      if (!isFirstTimeslotValid) {
        const validTimeslots = value.filter(timeslot =>
          validateTimeSlot(state, timeslot.get('start'), timeslot.get('date'))
        );
        if (!validTimeslots.some(timeslot => !!timeslot.get('available'))) timeslots = timeslots.delete(key);
        else timeslots = timeslots.set(key, validTimeslots);
      }
    }
  });
  return timeslots;
};

export const selectFulfilmentMethodTimeSlots = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  const timeslots = (fulfilmentMethod && fulfilmentMethod?.get('timeslots')) || new Map();

  return getFilteredTimeSlots(state, timeslots);
};

export const fulfilmentMethodHasAvailableTimeSlots = state => {
  const fulfilmentMethodTimeSlots = selectFulfilmentMethodTimeSlots(state);
  return !!fulfilmentMethodTimeSlots.size;
};

export const selectChosenTimeSlot = state => state.getIn(['storage', 'data', 'fulfilmentDateTime']);

export const selectTimeSlot = state => {
  if (!fulfilmentMethodHasAvailableTimeSlots(state)) return null;
  const chosenTimeSlot = selectChosenTimeSlot(state);
  if (chosenTimeSlot) return chosenTimeSlot.toJS();
  const timeslotDays = selectFulfilmentMethodTimeSlots(state);
  const firstSlot = timeslotDays.first().find(timeslot => !!timeslot.get('available'));
  const time = isPreOrderDatesOnly(state) ? '00:00' : firstSlot?.get('start');

  return firstSlot ? { date: firstSlot.get('date'), time } : null;
};

export const selectFulfilmentMethodRequireCovers = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('request_covers', false);
};

export const selectFulfilmentMethodRequireFullRegistration = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('require_full_registration', false);
};

export const selectFulfilmentMethodRequireCarMake = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('require_car_make', false);
};

export const selectFulfilmentMethodRequireCarModel = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('require_car_model', false);
};

export const selectFulfilmentMethodRequireCarColour = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('require_car_colour', false);
};

export const selectFulfilmentMethodRequireBayNumber = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('require_bay_number', false);
};

export const selectFulfilmentMethodNotes = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  const fulfilmentMethodNoteTranslations = fulfilmentMethod?.get('translations', false);
  const userLocale = selectUserDefaultLocale(state) || getLocale(state);
  const notes =
    fulfilmentMethodNoteTranslations && fulfilmentMethodNoteTranslations.getIn([userLocale, 'notes'], false);
  return fulfilmentMethod && urlTransform(notes || fulfilmentMethod.get('notes'));
};

export const selectFulfilmentMethodDisplayCutleryQuantity = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('request_cutlery', false);
};

export const selectActiveMenuTypes = (state, menuList = new Map()) =>
  menuList
    .filter(menu => menu.get('enabled'))
    .filter(menu => !!getIsStaff(state) || !menu.get('staffOnly'))
    .filter(menu => {
      // Filter out menus which are not enabled on any service in this venue

      // If the menu has no explicit service relations, then it is enabled everywhere
      if (!menu.get('services')?.size) {
        return true;
      }

      // The menu does have a nonempty list of services on which it's enabled, check if any belong to the current venue
      const venueServiceIds = selectServices(state)
        .valueSeq()
        .map(service => service.get('id'))
        .toArray();
      return menu.get('services').some(serviceId => venueServiceIds.includes(serviceId));
    })
    .reduce((menuTypes, menu) => {
      let updatedMenuTypes = menuTypes;
      menu.get('types').forEach(id => {
        updatedMenuTypes = updatedMenuTypes.add(id);
      });
      return updatedMenuTypes;
    }, new Set())
    .sort((a, b) => (a < b ? -1 : 1)) // Convert to list and sort to ensure a consistent order.
    .map(id => selectMenuTypeById(state, id))
    .toList();

export const selectFulfilmentMethodIntervalTime = state => {
  const fulfilmentMethod = selectFulfilmentMethod(state);
  return fulfilmentMethod && fulfilmentMethod.get('timeslot_interval');
};

export const selectVenueMenuTypes = state => selectActiveMenuTypes(state, selectMenus(state));

// select current menu types on menu, service, or venue
export const selectCurrentMenuTypes = state => {
  const menuId = selectMenuId(state);
  const serviceId = selectServiceId(state);

  // if menu id is set, return menu types on menu
  if (menuId) {
    const currentMenu = selectMenus(state).get(menuId);
    return selectActiveMenuTypes(state, new Map({ [menuId]: currentMenu }));
  }

  // if service id is set, return service menu types
  if (serviceId) {
    const serviceMenus = selectMenus(state).filter(
      menu => menu.get('services').includes(parseInt(serviceId, 10)) || !menu.get('services').size
    );
    return selectActiveMenuTypes(state, serviceMenus);
  }

  // return all menu types on venue
  return selectVenueMenuTypes(state);
};

export const selectServiceMenuTypes = state => {
  const fulfilmentMethods = selectAvailableFulfilmentMethods(state);
  const serviceMenuTypes = fulfilmentMethods
    .toJS()
    .reduce((prev, method) => [...prev, ...method.menu_types], []);
  return fromJS(serviceMenuTypes);
};

export const selectTabEnabledByServiceId = state =>
  state.getIn(['venue', 'services', getServiceId(state), 'allow_tab']);
export const selectBlockRequired = state =>
  state.getIn(['venue', 'services', getServiceId(state), 'block_required']) &&
  selectFulfilmentMethodId(state) === 5;

export const selectTabPaymentMethod = state => {
  const paymentMethods = selectPaymentMethods(state);
  return paymentMethods.reduce(
    (reduction, method) => (method.getIn(['group', 'key']) === 'tab' ? method : reduction),
    new Map()
  );
};

export const selectVenueAllergensMessage = state =>
  state.getIn(['venue', 'no_allergens_disclaimer']) || (
    <FormattedMessage {...allergenMessages.contact_staff} />
  );

export const selectVenueConsentMessage = state => state.getIn(['venue', 'marketing_consent_message']);

export const getVenueMarketingConsent = state => state.getIn(['venue', 'collect_marketing_consent']);

export const selectVenueCheckoutTerms = state => urlTransform(state.getIn(['venue', 'additional_terms']));

export const selectExternalLinks = state => state.getIn(['global', 'external_links'], false);
export const selectSidebarLinks = state =>
  selectExternalLinks(state)
    ? selectExternalLinks(state)?.filter(link => link.get('type') === 'sidebar_link')
    : [];
export const selectFooterLinks = state =>
  selectExternalLinks(state)
    ? selectExternalLinks(state)?.filter(link => link.get('type') === 'footer_link')
    : [];
export const isOfflineVenueMode = state => !getIsAdmin(state) && getIsVenueOffline(state);

export const isDeliveryToAddressEnabled = state => {
  const methodsWithMenuType = selectAvailableFulfilmentMethods(state);

  const deliveryToAddressMethod = methodsWithMenuType?.find(
    method => method.get('fulfilment_method_id') === 3
  );

  return Boolean(deliveryToAddressMethod);
};

export const selectFulfilmentMethodRadius = state => {
  const methodsWithMenuType = selectAvailableFulfilmentMethods(state);
  const deliveryToAddressMethod = methodsWithMenuType?.find(
    method => method.get('fulfilment_method_id') === 3
  );

  return deliveryToAddressMethod ? deliveryToAddressMethod.get('radius') : null;
};

export const getVenueLatitude = state => state.getIn(['venue', 'lat'], false);
export const getVenueLongitude = state => state.getIn(['venue', 'lng'], false);
export const getVenueCountryCode = state => state.getIn(['venue', 'country_code'], 'gb');

export const selectVenueTranslations = state => state?.getIn(['venue', 'translations'], false);

export const selectMessageByLocale = (state, msg) => {
  const userLocale = selectUserDefaultLocale(state) || getLocale(state);
  const venueTranslations = selectVenueTranslations(state);
  const message = venueTranslations && venueTranslations.getIn([userLocale, msg], false);

  return message;
};
export const selectGratuityMessageByLocale = state => selectMessageByLocale(state, 'gratuity_message');

export const selectVenueDeliveryPartners = state =>
  state.getIn(['venue', 'external_delivery_partners'], false);

export const selectVenueTimezone = state => state.getIn(['venue', 'timezone'], false);

export const selectVenueFeedbackUrl = state => state.getIn(['venue', 'feedbackUrl'], false);

export const selectGratuityOptions = (state, serviceId) =>
  state.getIn(['venue', 'services', serviceId, 'gratuity_options'], false);

export const selectGratuityAllowOther = (state, serviceId) =>
  state.getIn(['venue', 'services', serviceId, 'gratuity_options_allow_other'], false);
