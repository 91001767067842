import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';

import { isPreOrderDatesOnly } from 'selectors/features';
import { WaiterIcon } from 'components/Icons';
import { selectCollectionPoints } from 'selectors/order';
import { selectCompletedOrderPromotionCoupon } from 'reducers/root';
import { getIcon } from 'utils';
import RemainingBalance from 'components/RemainingBalance';
import { MessageContainer, SeparatorBlock } from '../styles';
import { Container, CouponDetails, IconContainer, Text } from './styles';
import getMessage from './getMessage';

const width = '75px';
const height = '75px';

const FulfilmentMessage = ({ collectionPoints, fulfilment, preOrderDatesOnly, promotionCoupon }) => (
  <>
    <SeparatorBlock />
    <MessageContainer>
      <Container>
        <IconContainer>
          {getIcon(fulfilment.location_type, {
            defaultIcon: <WaiterIcon width={width} height={height} />,
            width,
            height,
          })}
        </IconContainer>

        <Text>{getMessage(fulfilment, collectionPoints, preOrderDatesOnly)}</Text>
      </Container>
      {!!promotionCoupon && (
        <CouponDetails>
          <Text>
            Coupon: <code>{promotionCoupon.get('code')}</code>
          </Text>
          <RemainingBalance amount={promotionCoupon.get('balance')} />
        </CouponDetails>
      )}
    </MessageContainer>
  </>
);

FulfilmentMessage.defaultProps = {
  fulfilment: {},
  collectionPoints: new List(),
  preOrderDatesOnly: false,
};

FulfilmentMessage.propTypes = {
  collectionPoints: PropTypes.instanceOf(List),
  promotionCoupon: PropTypes.instanceOf(Map),
  fulfilment: PropTypes.object,
  preOrderDatesOnly: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  collectionPoints: selectCollectionPoints(state, ownProps.serviceId),
  preOrderDatesOnly: isPreOrderDatesOnly(state),
  promotionCoupon: ownProps.promotionCoupon || selectCompletedOrderPromotionCoupon(state),
});

export default connect(mapStateToProps)(FulfilmentMessage);
