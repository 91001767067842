import React from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  getItemPrice,
  getProductBreadcrumbs,
  getMenuSectionById,
  getProductById,
  productOutOfStock,
  productIsFiltered,
} from 'selectors/browse';
import { isOfflineVenueMode } from 'selectors/root';
import { quantityOfItemsInOrder } from 'selectors/order';
import { openProductInfo } from 'actions/UI';
import { fetchSection } from 'actions/menu';

import { addCssPrefixTo } from 'utils';
import ProductBar from './ProductBar';
import ProductGridView from './ProductGridView';

import messages from './messages';
import { Item } from './styles';

class Product extends React.Component {
  static defaultProps = {
    isFiltered: false,
    isDisabled: false,
    isOutOfStock: false,
    isAvailable: true,
    loading: false,
    breadcrumbs: null,
    shouldFetchSection: true,
    inOrder: 0,
  };

  static propTypes = {
    menuTypeId: PropTypes.number,
    price: PropTypes.number,
    isFiltered: PropTypes.bool,
    isAvailable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isOutOfStock: PropTypes.bool,
    item: PropTypes.instanceOf(Map),
    product: PropTypes.instanceOf(Map),
    additionalHeaderButtons: PropTypes.array,
    searchedFor: PropTypes.bool,
    reference: PropTypes.func,
    handleShowInfo: PropTypes.func,
    loading: PropTypes.bool,
    isGridView: PropTypes.bool,
    breadcrumbs: PropTypes.array,
    fetchSection: PropTypes.func,
    shouldFetchSection: PropTypes.bool,
    inOrder: PropTypes.number,
    isOfflineVenue: PropTypes.bool,
    serviceId: PropTypes.string,
  };

  state = {
    openInfoOnLoad: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      if (this.state.openInfoOnLoad) {
        this.showInfo();
        this.setOpenInfoOnLoad(false);
      }
    }
  }

  setOpenInfoOnLoad = (open = true) => this.setState({ openInfoOnLoad: open });

  showInfo = () => {
    if (this.runCheck()) return;

    if (this.props.loading) {
      if (this.props.shouldFetchSection) this.props.fetchSection();
      return this.setOpenInfoOnLoad();
    }

    this.props.handleShowInfo(this.props.item, this.props.price);
  };

  runCheck = () => {
    const { isOfflineVenue, isFiltered, isAvailable, isDisabled, isOutOfStock } = this.props;

    return isOfflineVenue || isFiltered || !isAvailable || isDisabled || isOutOfStock;
  };

  render() {
    const {
      product,
      reference,
      searchedFor,
      price,
      isAvailable,
      isDisabled,
      isOutOfStock,
      additionalHeaderButtons,
      loading,
      isGridView,
      breadcrumbs,
      inOrder,
      serviceId,
    } = this.props;
    if (!product.size) return null;

    const disabledProduct = isDisabled || isOutOfStock;
    const disabledMessage = (() => {
      if (isDisabled) return <FormattedMessage {...messages.currentlyUnavailable} />;
      if (isOutOfStock) return <FormattedMessage {...messages.outOfStock} />;
      return null;
    })();

    return (
      <Item
        className={addCssPrefixTo('MENU_ITEM')}
        ref={reference}
        filtered={this.runCheck()}
        searched={searchedFor}
        gridView={isGridView}
      >
        {isGridView && (
          <ProductGridView
            imagePath={product.getIn(['images', 0, 'path'])}
            product={product}
            price={price}
            onClick={this.showInfo}
            isLoading={loading && this.state.openInfoOnLoad}
            isAvailable={isAvailable}
            inOrder={inOrder}
            disabledMessage={disabledMessage}
          />
        )}
        {!isGridView && (
          <ProductBar
            product={product}
            price={price}
            isAvailable={isAvailable}
            isDisabled={disabledProduct}
            disabledMessage={disabledMessage}
            isLoadingInfo={loading && this.state.openInfoOnLoad}
            additionalButtons={additionalHeaderButtons}
            showInfo={this.showInfo}
            breadcrumbs={breadcrumbs}
            inOrder={inOrder}
            serviceId={serviceId}
          />
        )}
      </Item>
    );
  }
}

const mapStateToProps = (state, { item, menuTypeId, showBreadcrumb }) => {
  const section = getMenuSectionById(state, item?.get('menu_id'), item?.get('section_id'));
  const productId = item.get('productId');
  const product = getProductById(state, productId);

  return {
    isOfflineVenue: isOfflineVenueMode(state),
    inOrder: quantityOfItemsInOrder(state, productId),
    item: section?.get('section_products').find(sectionItem => sectionItem.get('id') === item.get('id')),
    loading: section?.get('slimProducts'),
    breadcrumbs:
      showBreadcrumb && getProductBreadcrumbs(state, item?.get('menu_id'), item?.get('section_id')),
    isOutOfStock: productOutOfStock(state, productId),
    price:
      menuTypeId &&
      getItemPrice(
        state,
        item.getIn(['prices', menuTypeId.toString(), 'price']),
        product.get('tax_categories')
      ),
    product,
    isFiltered: productIsFiltered(state, productId),
    isDisabled: !(item.get('enabled') && product.get('enabled') && !product.get('snoozed')),
  };
};

const mapDispatchToProps = (dispatch, { item }) => ({
  handleShowInfo: item => dispatch(openProductInfo(item)),
  fetchSection: () => dispatch(fetchSection(item?.get('section_id'))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Product);
