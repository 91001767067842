import React from 'react';
import { defineMessage } from 'react-intl';
import Input from '../../Input';

const roomNameInput = defineMessage({
  defaultMessage: 'Surname',
});

interface Props {
  value: string;
  onChange: (value: string) => void;
}

// Only it's own component so it can have a specific label
const NameInput: React.FC<Props> = ({ value, onChange }) => (
  <Input type="text" value={value} label={roomNameInput.defaultMessage} onChange={onChange} />
);

export default NameInput;
