import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';

import { showPaymentError, paymentErrorMessage } from 'selectors/payment';
import MessageBlock from 'components/MessageBlock';

import paymentMessages from 'components/Payment/intl';

const roomChargeMessages = defineMessages({
  'Surname mismatch': {
    defaultMessage: 'Surname does not match booking. Please check for spelling mistakes and try again.',
  },
  'Room number or account not found': {
    defaultMessage: 'Incorrect room number. Please check and try again.',
  },
  'Charge to room permission not present': {
    defaultMessage:
      'Unable to verify charge to room privilege. Please contact Reception to complete your purchase.',
  },
  'Account has insufficient balance': {
    defaultMessage: 'The account has insufficient funds to make the purchase.',
  },
  'Insufficient configuration': {
    defaultMessage: 'Unknown error, please contact a member of staff.',
  },
});

const getErrorMessage = errorMessage => {
  if (errorMessage) {
    if (roomChargeMessages[errorMessage]) {
      return <FormattedMessage {...roomChargeMessages[errorMessage]} />;
    }

    return errorMessage;
  }

  return <FormattedMessage {...paymentMessages.paymentErrorMessage} />;
};

const PaymentError = ({ showError, errorMessage }) => {
  if (!showError) return null;

  return (
    <MessageBlock
      header={<FormattedMessage {...paymentMessages.paymentError} />}
      body={getErrorMessage(errorMessage)}
      type="error"
    />
  );
};

PaymentError.propTypes = {
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default connect(state => ({
  showError: showPaymentError(state),
  errorMessage: paymentErrorMessage(state),
}))(PaymentError);
