import { selectMenusByType, selectMenuTypeId } from 'selectors/browse';

export default (dispatch, getState) =>
  ({ params, location }, replace) => {
    const state = getState();
    const menuTypeId = selectMenuTypeId(state);
    const serviceId = params.serviceId;
    const menus = selectMenusByType(state, menuTypeId, serviceId);
    if (menus.size === 1) {
      const search = location.search || '';
      replace(`/service/${serviceId}/menu/${menus.first().get('id')}${search}`);
    }
  };
