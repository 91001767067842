import styled from 'styled-components';

export const SummaryContainer = styled.div`
  height: 100%;
  border-radius: 5px;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.infoBackground};
  color: ${({ theme }) => theme.color.primaryText};
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const LineItem = styled.div`
  border-top: 1px solid #d7d7d7;
  padding: 10px 0;
`;

export const LineItemFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Modifier = styled.div`
  padding: 2px 5px;
`;

export const StyledPriceItem = styled(LineItem)`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
`;

export const StyledTaxBreakdown = styled(StyledPriceItem)`
  flex-direction: column;
  font-weight: normal;
`;

export const StyledPrice = styled.div`
  margin-left: 10px;

  html[dir='rtl'] & {
    margin-left: 0;
    margin-right: 10px;
  }
`;
