import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { StyledPrice, LineItem, LineItemFlex } from './styles';
import ProductPrice from '../../Product/price';

const PaymentLineItem = ({ text, price }) => (
  <LineItem>
    <LineItemFlex>
      <span style={{ textTransform: 'capitalize' }}>
        <FormattedMessage defaultMessage="Payment: " />
        {text}
      </span>
      <StyledPrice>
        <ProductPrice price={price} />
      </StyledPrice>
    </LineItemFlex>
  </LineItem>
);

PaymentLineItem.propTypes = {
  text: PropTypes.node,
  price: PropTypes.number,
};

export default PaymentLineItem;
