import { defineMessages } from 'react-intl';

export default defineMessages({
  editOrder: {
    defaultMessage: 'Edit Order',
  },
  emptyOrder: {
    defaultMessage: 'You have no items in your cart.',
  },
  finalTotal: {
    defaultMessage: 'Final Total',
  },
  remainingTotal: {
    defaultMessage: 'Remaining total',
  },
  subTotal: {
    defaultMessage: 'Subtotal',
  },
  tax: {
    defaultMessage: 'Tax',
  },
  taxTotal: {
    defaultMessage: 'Tax Total',
  },
  deliverCharge: {
    defaultMessage: 'Delivery Charge',
  },
  total: {
    defaultMessage: 'Total',
  },
  lineItem: {
    defaultMessage: '{formattedNumber} x {promotion} {name}',
  },
  edit_item: {
    defaultMessage: 'Edit Items',
  },
  remove_item: {
    defaultMessage: 'Remove Item',
  },
  has_notes: {
    defaultMessage: 'Has Notes',
  },
  promotion: {
    defaultMessage: 'Promotion: ',
  },
  payments: {
    defaultMessage: 'Payments',
  },
});
